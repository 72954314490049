import React, { useState } from "react";

const ImageModal = ({ src, alt, style }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div>
      {/* Miniature */}
      <img
        src={src}
        alt={alt}
        style={{ ...style, cursor: "pointer" }}
        onClick={openModal}
      />

      {/* Modale */}
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={closeModal} // Fermer la modale en cliquant à l'extérieur
        >
          <div
            style={{
              height: "96%",
            }}
            onClick={(e) => e.stopPropagation()} // Empêcher la fermeture en cliquant sur l'image
          >
            <img
              src={src}
              alt={alt}
              style={{
                maxHeight: "100%",
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
