import './../../Player.css';

import React, {useState, useRef, useContext, CSSProperties, useEffect} from 'react';
import ReactPlayer from 'react-player';
import { PlayerContext } from '../../PlayerContext';
import { openFullscreen, checkMobile, isTikTokInAppBrowser, getFileFormat, getFileNameFromUrl, isImg, isVideo, getTextWidth, lighterColor, printObj, isIOS, isAndroid, isFirefox, rgbaHexToRgbHexBlended, isSafari, isInstagramInAppBrowser, isEdge, browserLocales, isIpad } from '../../Utils';
import { analyticsManager } from '../../../index';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import Sticker from './Sticker';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Image} from 'react-native';
import Menu from './Menu';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from "@sentry/react";
import { StickerContext } from '../../StickerContext';
import CloseButton from '../../side_menu/components/CloseButton';
import { useAnimate } from 'framer-motion';
import Infos from './Infos';
import { set } from 'firebase/database';
import Promotion from './Promotion';

export const menuType = "menu";
export const insideType = "inside";

var interactedWithGallery = false;
var menuGalleryOpened = false;

function StickerGallery(props){
    const [isMediasGalleryOpened, setIsMediasGalleryOpened] = useState(false);
    const [isAfterOpening, setIsAfterOpening] = useState(false);
    const [isVirtualTourOpened, setIsVirtualTourOpened] = useState(false);
    const [retries, setRetries] = useState(0);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [closeGalleryWithMenu, setCloseGalleryWithMenu] = useState(false);

    const retriesNb = 1;
    const pauseVideo = useContext(PlayerContext).get("pauseVideo");

    const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
    const activeFeature = useContext(PlayerContext).get("activeFeature");
    menuGalleryOpened = activeFeature === "menu";
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const setInsideStickerClicked = useContext(PlayerContext).get("setInsideStickerClicked");
    const setMenuStickerClicked = useContext(PlayerContext).get("setMenuStickerClicked");
    const windowDimensions = useContext(PlayerContext).get("windowDimensions");
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier"); 
    const [menuHeight, setMenuHeight] = useState(storyDimensions.containerHeight * 9 / 16);
    const primaryColor = useContext(PlayerContext).get("primaryColor");
    const secondaryColor = useContext(PlayerContext).get("secondaryColor");
    const menuColor = useContext(PlayerContext).get("menuColor");
    const titleFont = useContext(StickerContext).get("menuTitleFont");
    const textFont = useContext(StickerContext).get("menuTextFont"); 
    const titleFontSizeMult = titleFont === "Bestro" ? 2 : 1;
    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");   
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const playMode = useContext(PlayerContext).get("playMode");
    const fromDemo = useContext(PlayerContext).get("fromDemo");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const idPrefix = fromDemo ? (fromDemo +"-") : "";
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });     
    const snackSwipeAlreadyDisplayed = useContext(StickerContext).get("snackSwipeAlreadyDisplayed");
    const setSnackSwipeAlreadyDisplayed = useContext(StickerContext).get("setSnackSwipeAlreadyDisplayed");
    const playAudio = useContext(PlayerContext).get("playAudio");    
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");  
    const [musicPlayingAtOpening, setMusicPlayingAtOpening] = useState(false); 
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const [neverSwiped, setNeverSwiped] = useState(true);

    const { t } = useTranslation();
    const [scope, animate] = useAnimate();

    const showMenu = activeFeature == null || activeFeature === "menu";
    const showInside = activeFeature == null || activeFeature === "inside";

    let title = t('MediasGalleryTitle');
    if (props.mediasType === insideType){
        if (props.story && props.story.posId === "ChIJC4g4KBNx5kcRH_z3vKUpnZw" ){
            title = "Galerie";
        }
        else if (props.story && (props.storyId === "32etJrIVkk0BjlVtPLG1" || props.story.baseStoryId === "32etJrIVkk0BjlVtPLG1") ){
            title = t("DishesGalleryTitle");
        }
        else {
            title = t('VirtualTourTitle');
        }
    }

    useEffect(() => {
        if (isAfterOpening && props.medias && props.medias[0]){            
            const element = document.getElementById("gallery-overlay");
            if (element != null){  
                if (!isVideo(getFileFormat(getFileNameFromUrl(props.medias[0])))){
                    const animation = async () => {                    
                        await animate(element, { opacity: 1}, {duration: 0.3});  
                        setIsAfterOpening(false);                  
                    }                    
                    animation();
                }
                else {
                    setIsAfterOpening(false); 
                    element.style.opacity = 1;
                }
            }                        
        }        
    }, [isAfterOpening]);

    useEffect(()=>{
        // NOT gallery appearance condition
        if (!(((isMediasGalleryOpened && props.mediasType === menuType) || (isVirtualTourOpened && props.mediasType === insideType)) && ((props.mediasType === menuType && activeFeature === "menu") || (props.mediasType === insideType && activeFeature === "inside")))){
            if (musicPlayingAtOpening){
                const ambiantPlayer = document.getElementById(idPrefix + "audio-player");                
                if (musicPlayingAtOpening && ambiantPlayer){
                    ambiantPlayer.play();   
                    setMusicPlayingAtOpening(false);
                }   
            }                  
            muteGalleryVideos();           
        }
    }, [isMediasGalleryOpened, isVirtualTourOpened, activeFeature, props.mediasType]);

    function muteGalleryVideos(){        
        const galleryContainer = document.getElementById("gallery-container");
        const videoElmts = galleryContainer != null ? galleryContainer.getElementsByTagName("video") : null;
        // reset volume btns
        if (props.medias && galleryContainer && videoElmts){
            for (let i = 0; i< props.medias.length; i++){                
                const volumeOn = document.getElementById(idPrefix + "volume-btn" + i + "-on");
                const volumeOff = document.getElementById(idPrefix + "volume-btn" + i + "-off");
                if (volumeOn && volumeOff){
                    if (volumeOn.style.visibility === "visible" || volumeOff.style.visibility === "visible"){                        
                        volumeOn.style.visibility = "visible"
                        volumeOff.style.visibility = "hidden";
                    }
                    else {
                        // case where the video has no sound                        
                    }
                }                
            }
        }

        if (videoElmts != null && videoElmts.length > 0){
            for (let i = 0; i < videoElmts.length; i++){
                videoElmts[i].muted = true;
            }
        }
    }    
     
    useEffect(() => {           
          
        if (activeFeature === "menu" && !interactedWithGallery && !(! checkMobile() || isTikTokInAppBrowser() && isIOS() && !isAndroid() || isFirefox() && isAndroid())){   
            const contentNb = (props.story.withDayMenu ? 1 : 0) + (props.story.withMenu ? 1 : 0) + (props.medias != null ? props.medias.length : 0); 
            if (contentNb === 1) return;                    
            setTimeout(()=>{
                if (interactedWithGallery) return;
                sleep(0)
                    .then(() => { 
                        const element = document.getElementsByClassName("axis-horizontal")[0];                        
                        if (interactedWithGallery || !menuGalleryOpened) throw Error();
                        if (element != null){
                            const scrollWidth = storyDimensions.containerWidth <= 400 ? 40 : (storyDimensions.containerWidth - 400)/2 + 24;
                            element.scroll({
                                top: 0,
                                left: scrollWidth,
                                behavior: "smooth",
                            });
                        }                
                    })
                    .then(()=>sleep(1000))
                    .then(()=>{                        
                        const element = document.getElementsByClassName("axis-horizontal")[0];
                        if (!menuGalleryOpened) throw Error();                        
                        if (element != null){
                            element.scroll({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });  
                            interactedWithGallery = true;                                                      
                        } 
                    })
                    .catch((error)=>{});
            }, 1500);                        
        }

        if (! activeFeature){
            setIsMenuOpened(false);
        }

        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
          }
      }, [activeFeature]);

    const openMediasGallery = () => {                     
        if (!fromMaker && !fromDemo && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){            
            const ambiantPlayer = document.getElementById(idPrefix + "audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
                try {
                    ambiantPlayer.play();                
                } catch(error){}
            }
            setPlayAudio(true);
        }
        pauseVideo(); 
        //checkBgVideoPlaying();
        openFullscreen();
        setMusicPlayingAtOpening(true && playAudio);
        setActiveFeature("menu");
        setIsMediasGalleryOpened(true);  
        setIsAfterOpening(true);  
        setMenuStickerClicked(true);
            
        analyticsManager.logOpenMenuTag(props.storyId, playMode, true);

        if (appAnalyticsManager)
            appAnalyticsManager.clickedMenu();  

        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "menu",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "menu"});
        }
        
        // Display directly the menu        
        if (props.mediasType === menuType && props.medias == null && !props.story.withDayMenu){             
            setCloseGalleryWithMenu(true);
            selectForFullScreen(0, null);
            // when displaying an integrated menu, we have to close the gallery frame
            if ((props.story.menuFile != null || props.story.menuFileEn != null) && (!props.fromMaker || props.fromMaker && !props.story.menuOptimizedFormat) && !(props.story.menuOptimizedFormat && props.story.menuId != null)){                   
                propagateFocusChange(true);
            }
        }

      }; 

    const openVirtualTour = () => {       
        if (!fromMaker /*&& !fromDemo*/ && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){            
            const ambiantPlayer = document.getElementById(idPrefix + "audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
                try {
                    ambiantPlayer.play();                
                } catch(error){}
            }
            setPlayAudio(true);
        }
        pauseVideo(); 
        //checkBgVideoPlaying();        
        openFullscreen();
        setMusicPlayingAtOpening(true && playAudio);
        setActiveFeature("inside");
        setIsVirtualTourOpened(true);
        setIsAfterOpening(true);
        setInsideStickerClicked(true);

        analyticsManager.logOpenInsideTag(props.storyId, playMode, true);

        if (appAnalyticsManager)
            appAnalyticsManager.clickedInside();

        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "inside",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "inside"});
        }
    };      

    // TODO determine best dim to configure the zoom 
    //https://coolboi567.medium.com/dynamically-get-image-dimensions-from-image-url-in-react-d7e216887b68

    /*let selectedItemInCarousel = props.selectedItem;
    if (props.medias != null){        
        for (let i = 0; i < props.medias.length; i++) {
            if (props.medias[i] === props.selectedItem){
                selectedItemInCarousel = i;
                break;
            }
        }
    }
    else {
        // TODO menu file in virtual tour ???
        if (props.mediasType === menuType && props.story.withMenu && props.story.withDayMenu){
            if (props.story.menuFile == props.selectedItem){
                selectedItemInCarousel = 1;
            }
            else if(props.story.dayMenuFile == props.selectedItem){
                selectedItemInCarousel = 0;
            }
        }
        else if (props.mediasType === menuType && (props.story.withMenu && props.story.menuFile == props.selectedItem || props.story.withDayMenu && props.story.dayMenuFile == props.selectedItem)){
            selectedItemInCarousel = 0;
        }
    }*/

    function selectForFullScreen(idx, media){                
        const contentNbWithFS = (props.story.withDayMenu ? 1 : 0) + (props.story.withMenu ? 1 : 0);
        
        if (idx >= contentNbWithFS)
            return;    
           
        const slides = document.getElementsByClassName("slide selected");      
        try{
            if (slides != null && slides.length !== 0){
                slides[0].children[0].children[0].pause();                
            }
        } catch(error){
            Sentry.captureMessage(printObj(error));
        }
                
        if (props.mediasType === menuType && props.story.withMenu && idx === ((props.story.withDayMenu ? 1 : 0) + (props.story.withMenu ? 1 : 0) - 1)){                 
            if (props.story.menuId != null && props.story.menuOptimizedFormat){                
                setIsMenuOpened(true);      
                if (props.fromMaker)
                    props.setStoryPreviewCurrentScreen("menu");
                analyticsManager.logOpenFullMenuTag(props.storyId, playMode, "internal", true);
            }
            /*else if (isImg(getFileFormat(getFileNameFromUrl(props.story.menuFile)))){                
                props.setFullScreen(props.story.menuFile, true);
            }*/
            else {
                if (playMode === "preview" && props.story.menuReformatDate != null || playMode == "demo" || props.storyId === "cafeupreality" || props.storyId === "cafeuprealityad"){                    
                    setIsMenuOpened(true);    
                    if (props.fromMaker)
                        props.setStoryPreviewCurrentScreen("menu");  
                    analyticsManager.logOpenFullMenuTag(props.storyId, playMode, "internal", true);
                }
                else {
                    const language = browserLocales(true)[0] === "fr" ? "fr" : "en";                    
                    if (language === "fr" && props.story.menuFile || language === "en" && !props.story.menuFileEn) {
                        window.open(props.story.menuFile, "_blank");
                    }
                    else if (language === "en" && props.story.menuFileEn || language === "fr" && !props.story.menuFile) {
                        window.open(props.story.menuFileEn, "_blank");
                    } 

                    analyticsManager.logOpenFullMenuTag(props.storyId, playMode, "external", true);
                }                
            }
        }
        /*else {
            props.setFullScreen(media, true);
            analyticsManager.logPlayContentTag("full_screen", media);        
        }*/
              
    }       
    
    function closeOrFullScreen(clientEvent){             
        let relativeX = clientEvent.clientX - (windowDimensions.width - storyDimensions.containerWidth)/2.0; 
        let relativeY = clientEvent.clientY - (windowDimensions.height - storyDimensions.containerHeight)/2.0 ;        
        if (props.fromMaker && ! isTabletOrMobile){     
            relativeX = clientEvent.clientX - (window.innerWidth - storyDimensions.containerWidth)/2.0;       
            relativeY = clientEvent.clientY - (window.innerHeight - (storyDimensions.containerHeight + 40))/2.0 - 40;
        }     
        if (playMode === "demo"){
            relativeX = clientEvent.clientX - document.getElementsByClassName("App")[0].getBoundingClientRect().left;
            relativeY = clientEvent.clientY - document.getElementsByClassName("App")[0].getBoundingClientRect().top;
        }   
        let selectedMediaContainer = document.getElementsByClassName("selected slide")[0];
        if (selectedMediaContainer == null){
            selectedMediaContainer = document.getElementsByClassName("slide")[0];
        }
        
        const firstContent = selectedMediaContainer == document.getElementsByClassName("slide")[0];
        const secondContent = selectedMediaContainer == document.getElementsByClassName("slide")[1];
        const isMenu = (props.story.withMenu && props.story.withDayMenu && secondContent) || (props.story.withMenu && !props.story.withDayMenu && firstContent);        

        const isImg = selectedMediaContainer.getElementsByTagName('img')[1] != null;
        const selectedMedia = isImg ? selectedMediaContainer.getElementsByTagName('img')[1] : selectedMediaContainer.getElementsByTagName('video')[0];   
        
        // on desktop emulating mobile, a click event can be triggered at gallery opening - see Sticker.js > onStickerTouchEnd
        if (selectedMedia == null && !isMenu){            
            return;
        }

        let contentWidth = 0;
        let contentHeight = 0;
        if (isMenu){
            contentWidth = Math.min(storyDimensions.containerWidth - 32 * miniMultiplier, 400);
            contentHeight = menuHeight;
        }   
        else {
            contentWidth = parseInt(isImg ? selectedMedia.width : selectedMedia.offsetWidth);
            contentHeight = parseInt(isImg ? selectedMedia.height : selectedMedia.offsetHeight);
        }  
        
        if (relativeY < (storyDimensions.containerHeight - contentHeight)/2.0){          
            propagateFocusChange(true);
        }
        else if (relativeY > (storyDimensions.containerHeight - contentHeight)/2.0 + contentHeight){                               
            propagateFocusChange(true);
        }
        else if (relativeX < (storyDimensions.containerWidth - contentWidth)/2.0){
            propagateFocusChange(true);
        }
        else if (relativeX > (storyDimensions.containerWidth - contentWidth)/2.0 + contentWidth){
            propagateFocusChange(true);
        }
        else {                 
            if (isMenu){   
                // workaround - for some reason opening the menu sticker (with 1 menu file + 1 dish img) make automatically a click on the gallery which would open the menu file
                if (!document.getElementById(idPrefix + "label-" + (firstContent ? 0 : 1))){                    
                    return;
                }                                       
                selectForFullScreen(firstContent ? 0 : 1, "");
            } 
            else if (isImg){                
                selectForFullScreen(selectedMedia.getAttribute('id'), selectedMedia.getAttribute('src'));
            }
        }
    }

    function closeFromButton(e){
        e.stopPropagation();
        propagateFocusChange(true);
    }

    function showCloseButton(){
        if (! checkMobile()) {
            return (<div style={{position: 'absolute', bottom: 0, right: 0}}>
                {<CloseButton onClick={(e) => closeFromButton(e)} color={props.story.primaryColor} width={42 * miniMultiplier} height={40 * miniMultiplier}/>}                
            </div>);
        }
    }

    const onImgLoaded = (id) => {        
        const imgLoaded = document.getElementById(id); 
        const reloadImage = imgLoaded != null && imgLoaded.naturalWidth > imgLoaded.naturalHeight;       
        if (reloadImage){
            imgLoaded.style.maxHeight = null;
            imgLoaded.style.maxWidth = storyDimensions.containerWidth - 32 * miniMultiplier + "px";
            imgLoaded.style.height = (storyDimensions.containerHeight / 2) + "px";                    
        }
    };

    const onVideoLoaded = (id) => {       
        const videoContainer = document.getElementById(id);        
        let videoTag = null;
        if (videoContainer != null){
            videoTag = videoContainer.getElementsByTagName("video")[0];
        }
        
        if (videoTag != null && videoTag.videoWidth > videoTag.videoHeight && videoTag.style.objectFit !== "cover"){  
            videoContainer.style.maxWidth = null;
            videoTag.style.maxWidth = null;
            videoTag.style.maxHeight = null;          
            videoTag.style.width = storyDimensions.containerWidth - 32 * miniMultiplier + "px";
            videoTag.style.height = (storyDimensions.containerHeight / 2) + "px";
            videoTag.style.objectFit = "cover";                       
        }        
    };

    function disableVolumeButtons(videoId, idx){
        const videoContainer = document.getElementById(videoId);        
        let videoTag = null;
        if (videoContainer != null){
            videoTag = videoContainer.getElementsByTagName("video")[0];
        }
        if (videoTag){            
            const hasAudio = videoTag.mozHasAudio || Boolean(videoTag.webkitAudioDecodedByteCount) || Boolean(videoTag.audioTracks && videoTag.audioTracks.length);            
            if (! hasAudio){
                const volumeBtnOn = document.getElementById(idPrefix + "volume-btn" + idx + "-on");
                const volumeBtnOff = document.getElementById(idPrefix + "volume-btn" + idx + "-off");
                if (volumeBtnOn != null && volumeBtnOff != null){                    
                    volumeBtnOn.style.visibility = "hidden";
                    volumeBtnOff.style.visibility = "hidden";
                }
            }
        }
    }

    const addLabelToSlide = (idx, contentHeight, contentWidth = null, isVideo = false) => {         
        // by simplicity no mini multiplier on height               
        if (document.getElementById(idPrefix + "label-" + idx) != null){
            return;        
        }
        var label = document.createElement("div");                
        label.id = idPrefix + "label-" + idx;      
        const topMargin = Math.max((storyDimensions.containerHeight - 32 - contentHeight)/2, 0);
        label.style.top = topMargin + "px";
        if (contentWidth != null){            
            label.style.left = Math.max((storyDimensions.containerWidth - 32 * miniMultiplier - contentWidth)/2 , 0) + "px";                          
        }
        else
            label.style.left = "0px";

        if (isVideo){
            const volumeBtnOn = document.getElementById(idPrefix + "volume-btn" + idx + "-on");
            const volumeBtnOff = document.getElementById(idPrefix + "volume-btn" + idx + "-off");
            if (volumeBtnOn != null && volumeBtnOff != null){
                volumeBtnOn.style.right = label.style.left;
                volumeBtnOff.style.right = label.style.left;
                if (contentHeight < Math.round(storyDimensions.containerHeight * 4/5)){                    
                    const bottomMargin = (storyDimensions.containerHeight * 4/5 - contentHeight) / 2;
                    volumeBtnOn.style.bottom = storyDimensions.containerHeight * 1/10 - miniMultiplier * 16 + bottomMargin + "px";
                    volumeBtnOff.style.bottom = storyDimensions.containerHeight * 1/10 - miniMultiplier * 16 + bottomMargin + "px";
                }
                volumeBtnOn.style.visibility = "visible";
            }
        }
        
        label.style.padding = "4px";
        label.style.borderBottomRightRadius = "8px";
        label.style.fontSize = 16 * miniMultiplier + "px";
        label.className = "MediasGalleryTitle";
        label.innerHTML = title;
        document.getElementById(idPrefix + "container-" + idx).appendChild(label);   
    };

    function showContent(css){        
        if (props.mediasType === menuType && (css.idx === 0 && props.story.withMenu && !props.story.withDayMenu || css.idx === 1 && props.story.withMenu && props.story.withDayMenu)){                    
            let titleStyle: CSSProperties = {};
            let imgSrc = "";
            let imgStyle: CSSProperties = {};
            let subtitleStyle: CSSProperties = {};

            switch (props.story.menuStyle){
                case "elegant":
                    titleStyle = {color: secondaryColor, fontSize: 70 * miniMultiplier, fontFamily: 'Alex brush'};
                    imgSrc = "/images/separatorElegant.png";
                    // TODO make it proportional to text length
                    imgStyle = {marginTop: 20 * miniMultiplier, width: 160 * miniMultiplier, height:11 * miniMultiplier};
                    subtitleStyle = {color: secondaryColor, fontSize: 16 * miniMultiplier, fontStyle: 'italic', marginTop: 40 * miniMultiplier};
                    break;
                case "friendly":
                    titleStyle = {color: secondaryColor, fontSize: 64 * miniMultiplier, fontFamily: 'Roboto condensed', fontWeight: 'bold'};
                    imgSrc = "/images/separatorFriendly.png";
                    imgStyle = {marginTop: 20 * miniMultiplier, width: getTextWidth(t('Menu'), 64 * miniMultiplier + "px Roboto condensed"), height:20 * miniMultiplier};
                    subtitleStyle = {color: secondaryColor, fontSize: 16 * miniMultiplier, fontStyle: 'italic', marginTop: 40 * miniMultiplier};
                    break;
                case "modern":
                    titleStyle = {color: secondaryColor, fontSize: 64 * miniMultiplier, fontFamily: 'Roboto condensed', fontWeight: 'bold'};
                    imgSrc = "";
                    imgStyle = {};
                    subtitleStyle = {color: secondaryColor, fontSize: 16 * miniMultiplier, fontStyle: 'italic', marginTop: 40 * miniMultiplier};
                    break;
                case "simple":                    
                    titleStyle = {color: secondaryColor, fontSize: 64 * miniMultiplier, fontFamily: 'Roboto'};
                    imgSrc = "/images/separatorSimple.png";
                    imgStyle = {marginTop: 20 * miniMultiplier, width: getTextWidth(t('Menu'), 64 * miniMultiplier + "px Roboto"), height:4 * miniMultiplier};
                    subtitleStyle = {color: secondaryColor, fontSize: 16 * miniMultiplier, fontStyle: 'italic', marginTop: 40 * miniMultiplier};

                    break;
                case "custom":                    
                    titleStyle = {color: secondaryColor, fontSize: 64 * titleFontSizeMult * miniMultiplier, fontFamily: titleFont ? titleFont : 'Roboto'};
                    imgSrc = "/images/separatorSimple.png";
                    imgStyle = {marginTop: 20 * miniMultiplier, width: getTextWidth(t('Menu'), 64 * titleFontSizeMult * miniMultiplier + "px " + (titleFont ? titleFont : 'Roboto')), height:4 * miniMultiplier};
                    subtitleStyle = {color: secondaryColor, fontFamily: textFont ? textFont : 'Roboto', fontSize: 16 * miniMultiplier, fontStyle: 'italic', marginTop: 40 * miniMultiplier};

                    break;
            }

            let finalMenuColor = menuColor;
            if (secondaryColor == lighterColor(primaryColor, secondaryColor)){
                finalMenuColor = secondaryColor;
            }

            function onMenuImgLoaded(imgIdx){
                const container = document.getElementById(idPrefix + "container-" + css.idx+"-img"+imgIdx);
                if (container != null){                    
                    container.style.visibility = "visible";                                
                }
            }
            
            if (props.story.menuStyle !== "modern"){                
                return (
                    <div id={idPrefix + "container-"+css.idx} onLoad={()=>addLabelToSlide(css.idx, menuHeight, Math.min(storyDimensions.containerWidth - 32 * miniMultiplier, 400))} className="medias-gallery-menu" style={{margin: "auto", background: primaryColor, width: Math.min(storyDimensions.containerWidth - 32 * miniMultiplier, 400)/*storyDimensions.containerWidth - 32 * miniMultiplier*/, height: menuHeight}}>     
                        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" width="100%" height="100%" style={{position: 'absolute'}}/>                           
                        <div style={{...titleStyle}}>{t('Menu')}</div>        
                        <Image id={idPrefix + "container-"+css.idx+"-img"} alt="menu header style" source={imgSrc} style={{ ...imgStyle}} tintColor={secondaryColor} />
                        <div style={{...subtitleStyle}}>{t('Click')} <span style={{color: finalMenuColor}}>{t('Here')}</span> {t('ToRead')}</div>                        
                    </div>
                );
            }
            else {          
                const titleSizeRoboto = getTextWidth(t('Menu'), 64 * miniMultiplier + "px Roboto condensed");      
                const separatorLeftX = 0;
                const separatorRightX = separatorLeftX + titleSizeRoboto + (32 - 110 + 32) * miniMultiplier;
                const titleY = (storyDimensions.containerHeight - menuHeight)/2 + (menuHeight - 140 * miniMultiplier) * (miniMultiplier === 1 ? 2.5/7 : 2/7);
                const subtitleY = titleY + 140 * miniMultiplier;

                return (                    
                    <div id={idPrefix + "container-"+css.idx} onLoad={()=>addLabelToSlide(css.idx, menuHeight, Math.min(storyDimensions.containerWidth - 32 * miniMultiplier, 400))} className="medias-gallery-menu" style={{margin: "auto", background: primaryColor, width: Math.min(storyDimensions.containerWidth - 32 * miniMultiplier, 400)/*storyDimensions.containerWidth - 32 * miniMultiplier*/, height: menuHeight}}> 
                        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" width="100%" height="100%" style={{position: 'absolute'}}/>
                        <div style={{position: 'absolute', top: titleY, width: titleSizeRoboto + 64 * miniMultiplier, height: 140 * miniMultiplier}}>                            
                            <div className='medias-gallery-menu-modern-title' style={{...titleStyle}}>{t('Menu')}</div>
                            <Image id={idPrefix + "container-"+css.idx+"-img1"} alt="menu header style" onLoad={()=>onMenuImgLoaded(1)} source="/images/separatorModernLeft.png" style={{visibility: 'hidden', left: separatorLeftX, height: 140 * miniMultiplier, width: 110 * miniMultiplier, position:'absolute', top: '50%', transform: 'translate(0%, -50%)'}} tintColor={secondaryColor}/>
                            <Image id={idPrefix + "container-"+css.idx+"-img2"} alt="menu header style" onLoad={()=>onMenuImgLoaded(2)} source="/images/separatorModernRight.png" style={{visibility: 'hidden', left: separatorRightX, height: 140 * miniMultiplier, width: 110 * miniMultiplier, position:'absolute', top: '50%', transform: 'translate(0%, -50%)'}} tintColor={secondaryColor}/>                            
                        </div>                                         
                        <div style={{...subtitleStyle, position: 'absolute', top: subtitleY}}>{t('Click')} <span style={{color: finalMenuColor}}>{t('Here')}</span> {t('ToRead')}</div>
                    </div>
                );
            }
        } 
        else {         
            
            function onImgLoad(){
                onImgLoaded(idPrefix + css.idx); 
                
                const imgElement = document.getElementById(idPrefix + css.idx);
                let imgWidth = imgElement.width;
                let imgHeight = imgElement.height;
                
                const maxWidth = Math.min(storyDimensions.containerWidth-32 * miniMultiplier, 400);
                const maxHeight = storyDimensions.containerHeight * 4/5;
                // the natural width is returned insead of the html element one
                const bug = imgElement.width > maxWidth && imgElement.height > maxHeight;
                if (bug){
                    if (imgElement.naturalHeight > imgElement.naturalWidth){
                        imgWidth = maxWidth
                        const imgHeightCalc = imgWidth * imgElement.naturalHeight / imgElement.naturalWidth;
                        imgHeight = Math.min(imgHeightCalc, maxHeight);
                    }
                    else {
                        imgHeight = storyDimensions.containerHeight / 2;                                                
                        imgWidth = Math.min(imgElement.naturalWidth * imgHeight / imgElement.naturalHeight, maxWidth);    
                    }
                }
                // si supérieur a max width height pas de label
                addLabelToSlide(css.idx, imgHeight, imgWidth);
            }

            return (<div id={idPrefix + "container-" + css.idx} style={{height: storyDimensions.containerHeight-32, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img style={{position: "absolute"}} src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" width="100%" height="100%"/>
                    <img onLoad={()=>{onImgLoad();}} id={idPrefix + css.idx} alt={t('RestaurantContent')} src={css.url} style={{maxWidth: Math.min(storyDimensions.containerWidth-32 * miniMultiplier, 400), maxHeight: storyDimensions.containerHeight * 4/5, objectFit: 'cover'}}/>
                </div>);
        }
    }

    const ImgSlide = (css) => {
        let clicker = null;      
        // gallery click for desktop
        if (! checkMobile()){
            clicker = () => selectForFullScreen(css.idx, css.url);
        }

        return (
            <div key={css.url} style={{height: storyDimensions.containerHeight-32, display: 'flex', flexDirection: "column", justifyContent: "center"}} onClick={clicker}>            
                <div className='not-selectable' style={{display: 'flex', flexDirection: "column", justifyContent: "center"}}>
                    {showContent(css)}                    
                </div>                
            </div>     
        );
    };

    function retry(){
        if (retries < retriesNb){
            const slides = document.getElementsByClassName("slide");    
            try{    
                if (slides != null && slides.length !== 0){
                    slides[0].children[0].children[0].load();
                }
            } catch(error){
                Sentry.captureMessage(printObj(error));
                setRetries(retries + 1);
            }
        }
    }
    
    const VideoSlide = (css) => {
        let clicker = null;
        // gallery click for desktop
        if (! checkMobile()){
            clicker = () => selectForFullScreen(css.idx, css.url);
        }
        const isSelected = css.isSelected || neverSwiped && css.idx === 0 && document.getElementsByClassName["slide selected"] == null;        

        function getVideoDisplayedWidthFromVideoTag(){
            const videoTag = document.getElementById(idPrefix + css.idx).getElementsByTagName("video")[0];
            const trueVideoAspectRatio = videoTag.videoHeight / videoTag.videoWidth;
            const videoDisplayedWidth = videoTag.offsetHeight / trueVideoAspectRatio;

            return videoDisplayedWidth;
        }

        function toggleSound(e){            
            e.stopPropagation();
            const video = document.getElementById(idPrefix + css.idx).getElementsByTagName("video")[0];
            const volumeBtnOn = document.getElementById(idPrefix + "volume-btn" + css.idx + "-on");
            const volumeBtnOff = document.getElementById(idPrefix + "volume-btn" + css.idx + "-off");
            if (video != null && volumeBtnOn != null && volumeBtnOff != null){
                const ambiantPlayer = document.getElementById(idPrefix + "audio-player");
                if (video.muted){
                    video.muted = false;
                    video.pause();
                    video.currentTime = 0;
                    video.play();
                    volumeBtnOff.style.visibility = "visible";
                    volumeBtnOn.style.visibility = "hidden";
                    if (musicPlayingAtOpening && ambiantPlayer){
                        ambiantPlayer.pause();
                    }  
                    if (window.ttq){
                        window.ttq.track('ViewContent', {                
                            "contents": [
                                {              
                                    "content_id": "0",           
                                    "content_name": "set volume on in the " + props.mediasType + " gallery",
                                }
                            ],
                        });
                    }  
                    if (window.fbq){                    
                        window.fbq('track', 'ViewContent', {"content_name": "set volume on in the " + props.mediasType + " gallery"});
                    }                      
                }
                else {
                    video.muted = true;
                    volumeBtnOff.style.visibility = "hidden";
                    volumeBtnOn.style.visibility = "visible";
                    if (musicPlayingAtOpening && ambiantPlayer){                                          
                        ambiantPlayer.play();
                    }
                    if (window.ttq){
                        window.ttq.track('ViewContent', {                
                            "contents": [
                                {              
                                    "content_id": "0",           
                                    "content_name": "set volume off in the " + props.mediasType + " gallery",
                                }
                            ],
                        });
                    }
                    if (window.fbq){                    
                        window.fbq('track', 'ViewContent', {"content_name": "set volume off in the " + props.mediasType + " gallery"});
                    } 
                }
            }
            
        }
        
        let poster = "/images/transparent_image.png";
        if (isIpad() && isInstagramInAppBrowser()){
            poster = "/images/ic_play_v.png";
            clicker = ()=>{
                const reactPlayerContainer = document.getElementById(idPrefix + css.idx);
                if (reactPlayerContainer != null){
                    const video = reactPlayerContainer.getElementsByTagName("video")[0];
                    if (video){
                        video.play();
                    }
                }
            };
        }

        return (
                <div id={idPrefix + "container-"+css.idx} style={{height: storyDimensions.containerHeight - 32, display: 'flex', flexDirection: "column", justifyContent: "center", }}>
                    <div style={{display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        {<ReactPlayer id={idPrefix + css.idx} style={{maxWidth: Math.min(storyDimensions.containerWidth-32 * miniMultiplier, 400), maxHeight: storyDimensions.containerHeight*4/5}} onStart={()=>{onVideoLoaded(idPrefix + css.idx);if (!(isIpad() && isInstagramInAppBrowser())) { addLabelToSlide(css.idx, document.getElementById(idPrefix + css.idx).getElementsByTagName("video")[0].offsetHeight, getVideoDisplayedWidthFromVideoTag(), true);}disableVolumeButtons(idPrefix + css.idx, css.idx);}} onError={() => retry()} onClick={clicker} width="100%" height="100%" url={css.url} playing={isSelected} playsinline muted={true} loop={true}  config={{attributes: {poster: poster, disablePictureInPicture: true}}}/>}                                   
                    </div>                    
                    <IconButton id={idPrefix + "volume-btn" + css.idx + "-on"} style={{visibility: 'hidden', position: 'absolute', bottom: storyDimensions.containerHeight * 1/10 - miniMultiplier * 16,  right: 0, padding: 8 * miniMultiplier}} onClick={(e)=>toggleSound(e)}>
                        <VolumeUpIcon style={{color: '#FFFFFF90', width: 32 * miniMultiplier, height:32 * miniMultiplier}} />
                    </IconButton>                    
                    <IconButton id={idPrefix + "volume-btn" + css.idx + "-off"} style={{visibility: 'hidden', position: 'absolute', bottom: storyDimensions.containerHeight * 1/10 - miniMultiplier * 16, right: 0,  padding: 8 * miniMultiplier}} onClick={(e)=>toggleSound(e)}>
                        <VolumeOffIcon style={{color: '#FFFFFF90', width: 32 * miniMultiplier, height:32 * miniMultiplier}} />
                    </IconButton>     
                </div>
            );
    }

    let mediasList = [];
    if (props.medias != null){
        mediasList = props.medias.map((media, idx) => {
                const fileFormat = getFileFormat(getFileNameFromUrl(media));

                let idxFinal = parseInt(idx); 
                if (props.mediasType === menuType)
                    idxFinal = parseInt(idx) + (props.story.withDayMenu ? 1 : 0) + (props.story.withMenu ? 1 : 0);
                if (isImg(fileFormat)){                               
                    return (
                        <ImgSlide key={media} url={media} idx={idxFinal} />
                    );    
                }
                else if (isVideo(fileFormat)){
                    // TODO pas de key ???
                    return (
                        <VideoSlide key={media} url={media} idx={idxFinal} />
                    );
                }
                else {

                    return null;
                }
                
            }     
        );
    }
    if (props.mediasType === menuType && props.story.withMenu){   
        const language = browserLocales(true)[0] === "fr" ? "fr" : "en";
        let keyUrl = "";
        if (language === "fr" && props.story.menuFile || language === "en" && !props.story.menuFileEn) {
            keyUrl = props.story.menuFile;
        }
        else if (language === "en" && props.story.menuFileEn || language === "fr" && !props.story.menuFile) {
            keyUrl = props.story.menuFileEn;
        }        
        mediasList.unshift(<ImgSlide key={keyUrl} url={keyUrl} idx={props.story.withDayMenu ? 1 : 0} />);
    }   

    if (props.mediasType === menuType && props.story.withDayMenu){
        mediasList.unshift(        
            (<ImgSlide key={props.story.dayMenuFile} url={props.story.dayMenuFile} idx="0" />)            
        );
    }

    const arrowStylesLeft: CSSProperties = {
        position: 'absolute',
        zIndex: 2,
        top: "calc(50% - "+ (24) + "px)",
        width: 48,
        height: 48,
        background: '#FFFFFF00',
        border: 0,        
    };

    const arrowStylesRight: CSSProperties = {
        position: 'absolute',
        zIndex: 2,
        top: "calc(50% - "+ (24) + "px)",
        width: 48,
        height: 48,
        background: '#FFFFFF00',
        border: 0,        
    };

    const customRenderItem = (item, props) => {
        return <item.type {...item.props} {...props} />
    };

    // swipe previous close the web page    
    const swipeable = ! (isTikTokInAppBrowser() && isIOS() && !isAndroid() || isFirefox() && isAndroid()); 

    function swipeContent(){
        setNeverSwiped(false);
        muteGalleryVideos();
        if (musicPlayingAtOpening){
            const ambiantPlayer = document.getElementById(idPrefix + "audio-player");
            if (musicPlayingAtOpening && ambiantPlayer){
                ambiantPlayer.play();
            }   
        }

        interactedWithGallery = true;    
        if (props.mediasType === menuType){
            analyticsManager.logSwipeMenuTag(props.storyId, playMode);
        }
        else if (props.mediasType === insideType){
            analyticsManager.logSwipeInsideTag(props.storyId, playMode);
        }    
        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "swipe in the " + props.mediasType + " gallery",
                    }
                ],
            });
        }   
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "swipe in the " + props.mediasType + " gallery"});
        }         
    }
    
    if (props.medias == null && (props.mediasType === insideType && !props.story.promotionImgUrl || props.mediasType === menuType && !props.story.withMenu && !props.story.withDayMenu)){                
        return null;
    }
    else if (((isMediasGalleryOpened && props.mediasType === menuType) || (isVirtualTourOpened && props.mediasType === insideType && !props.story.promotionImgUrl)) && ((props.mediasType === menuType && activeFeature === "menu") || (props.mediasType === insideType && activeFeature === "inside"))){                     

        return (
            <div className="MediasGalleryContainer">
                <div id="gallery-overlay" className="BlockingOverlay" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, opacity: 0, background: "#FFFFFFD0"}}/>                
                    <div id="gallery-container" className="MediasGallery" style={{width: storyDimensions.containerWidth - 32 * miniMultiplier, height: storyDimensions.containerHeight - 32, visibility: isMenuOpened ? 'hidden' : 'visible'}} onClick={(e)=>{if (checkMobile()) closeOrFullScreen(e);}}>
                        <Carousel swipeable={swipeable} selectedItem={/*selectedItemInCarousel != null ? selectedItemInCarousel :*/ 0} showArrows={! checkMobile() || isTikTokInAppBrowser() && isIOS() && !isAndroid() || isFirefox() && isAndroid()/* same cond reused in this file */} showStatus={false} showThumbs={false} dynamicHeight={true}  showIndicators={false} onChange={swipeContent}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>                         
                                hasPrev && (
                                    <button type="button" onClick={(e)=>{onClickHandler(); e.stopPropagation();}} title={label} style={{ ...arrowStylesLeft}}>                                
                                        <ArrowCircleLeftIcon onClick={(e)=>{onClickHandler(); e.stopPropagation();}} style={{color: '#FFFFFF90', width: 40 * miniMultiplier, height: 40 * miniMultiplier, zIndex: 4}}/>                                
                                    </button>
                                )                    
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <button type="button" onClick={(e)=>{onClickHandler(); e.stopPropagation();}} title={label} style={{ ...arrowStylesRight, right: 12 * miniMultiplier}}>                                        
                                            <ArrowCircleRightIcon onClick={(e)=>{onClickHandler(); e.stopPropagation();}} style={{color: '#FFFFFF90', width: 40 * miniMultiplier, height: 40 * miniMultiplier, zIndex: 4}}/>                                
                                    </button>
                                )
                            }
                            renderItem={customRenderItem}
                            className="Slide"
                            onSwipeMove={()=>{interactedWithGallery = true;}}
                            >                        
                            {mediasList}                        
                        </Carousel>  
                        {showCloseButton()}                                  
                    </div>  
                {  
                    props.story.posId !== "ChIJC4g4KBNx5kcRH_z3vKUpnZw"  &&
                        <Menu show={isMenuOpened} storyId={props.storyId} story={props.story} fromMaker={props.fromMaker} snackSwipeAlreadyDisplayed={snackSwipeAlreadyDisplayed} setSnackSwipeAlreadyDisplayed={setSnackSwipeAlreadyDisplayed} closeMenu={()=>{
                            setIsMenuOpened(false);
                            if (props.fromMaker)
                                props.setStoryPreviewCurrentScreen(null);
                            if (closeGalleryWithMenu){
                                propagateFocusChange(true);
                                setCloseGalleryWithMenu(false);
                            }
                            }}/>
                }
                {
                    props.story.posId === "ChIJC4g4KBNx5kcRH_z3vKUpnZw"  &&
                        <Infos show={isMenuOpened} storyId={props.storyId} story={props.story} fromMaker={props.fromMaker} snackSwipeAlreadyDisplayed={snackSwipeAlreadyDisplayed} setSnackSwipeAlreadyDisplayed={setSnackSwipeAlreadyDisplayed} closeMenu={()=>{
                            setIsMenuOpened(false);
                            if (props.fromMaker)
                                props.setStoryPreviewCurrentScreen(null);
                            if (closeGalleryWithMenu){
                                propagateFocusChange(true);
                                setCloseGalleryWithMenu(false);
                            }
                            }}/>
                }

            </div>
        );
    }
    else if (isVirtualTourOpened && props.mediasType === insideType && props.story.promotionImgUrl && activeFeature === "inside") {                
        return <Promotion story={props.story} close={closeFromButton}/>;
    }
    else {                
        if (props.mediasType === insideType){
           return <Sticker show={showInside} story={props.story} storyId={props.storyId} stickerType={insideType} open={openVirtualTour}/>;
        }
        else if (props.mediasType === menuType){
            return <Sticker show={showMenu} story={props.story} storyId={props.storyId} stickerType={menuType} open={openMediasGallery}/>;
        }
        else {            
            return null;
        }                        
    }
}    
   
export default StickerGallery;