import React, {useEffect, useRef, useState} from 'react';
import {AppContext} from './AppContext';
import {
    RouterProvider,
  } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import {app, analyticsManager} from './index';
import { getId, getInstallations } from '@firebase/installations';
import { ThemeProvider, createTheme } from '@mui/material';
import { getWindowDimensions, printObj } from './player/Utils';
import * as Sentry from "@sentry/react";

export const SOCIAL_WEBSITE_HP = "social_website_hp";
export const INSTANT_AD_HP = "instant_ad_hp";

function AppRoot(props) {    
    const [fbResponse, setFbResponse] = useState(null);
    const [makerDesktopHeight, setMakerDesktopHeight] = useState(calculateMakerDesktopHeight()); 
    const [justDeletedAccount, setJustDeletedAccount] = useState(false);
    const [homePageOrigin, setHomePageOrigin] = useState(null);
    const homePageOriginRef = useRef();
    homePageOriginRef.current = homePageOrigin;
    const lastStateConnectedRef = useRef();
    const [fbInit, setFbInit] = useState(false);
    const auth = getAuth();    

    useEffect(() => {
        homePageOriginRef.current = homePageOrigin;
    }, [homePageOrigin]);

    function calculateMakerDesktopHeight(){
        // min ratio for maker 16/9
        return Math.min(Math.max(640 + 48, getWindowDimensions().height + 48), 800 + 48 + 60) - 48 - 60; // top bar + small margin
    } 

    useEffect(() => {
        let unsubscribe = null;

        async function setUserIdFromFirebaseId() {
            const firebaseInstallationId = await getId(getInstallations(app)).catch((e)=>Sentry.captureMessage("get firebase installation id: " + printObj(e)));            
            if (firebaseInstallationId != null)
                analyticsManager.setUserId(firebaseInstallationId);
        }

        async function observeAuth(){ 
            unsubscribe = await getAuth().authStateReady()
                .then(() => {
                    const currentUser = getAuth().currentUser;
                    if (currentUser != null)
                        lastStateConnectedRef.current = true;
                })
                .then(() =>{
                    return onAuthStateChanged(auth, (user) => {
                        // console.log("onAuthStateChanged " + printObj(user));
                        // console.log(JSON.stringify(auth));
                        setFbInit(true);    
                        if (user) { 
                            setFbResponse(user);            
                            analyticsManager.setUserId(user.uid);
                            lastStateConnectedRef.current = true;
                        } else {
                            setFbResponse(null);
                            setUserIdFromFirebaseId();
                            
                            // TODO justDeleteAccount is not uptodate when the state change occurs
                            if (lastStateConnectedRef.current /* && ! justDeletedAccount*/){                                     
                                if (homePageOriginRef.current === INSTANT_AD_HP){
                                    window.location = "/Instant-Ad?ackDisconnected=true"; 
                                }   
                                else {                                            
                                    window.location = "/?ackDisconnected=true"; 
                                }
                            }                            
                        }
                    });                 
                }); 
        }

        observeAuth();

        return () => {
            if (unsubscribe != null)
                unsubscribe();        
        };
    }, []);
      
    
    return (
        <AppContext.Provider value={new Map([["fbResponse", fbResponse], ["setFbResponse", setFbResponse], ["fbInit", fbInit], ["makerDesktopHeight", makerDesktopHeight], ["justDeletedAccount", justDeletedAccount], ["setJustDeletedAccount", setJustDeletedAccount], ["homePageOrigin", homePageOrigin],["setHomePageOrigin", setHomePageOrigin]])}>   
            <ThemeProvider theme={createTheme({palette: {primary: {main: '#2FC4B5',},},})}>      
                <RouterProvider router={props.router} />
            </ThemeProvider>
        </AppContext.Provider>
    );
}

export default AppRoot;