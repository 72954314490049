import './../../Player.css';
import React, {useContext, useState, useEffect, useRef} from 'react';
import * as Sentry from "@sentry/react";
import { useTranslation } from 'react-i18next';
import { PlayerContext } from '../../PlayerContext';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Image} from 'react-native';
import { browserLocales, checkMobile, getTextWidth, isAndroid, isFacebookInAppBrowser, isFirefox, isIOS, isInstagramInAppBrowser, isSnapchatInAppBrowser, isTikTokInAppBrowser, printObj, addOpacityToHexColor, isIpad } from '../../Utils';
import { getDoc, doc, getFirestore } from 'firebase/firestore';
import MealDetail from './MealDetail';
import { analyticsManager } from '../../..';
import CloseButton from '../../side_menu/components/CloseButton';
import { set } from 'firebase/database';
import ImageButton from '../../side_menu/components/ImageButton';
import { StickerContext } from '../../StickerContext';
import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import AllergensSelect from '../AllergensSelect';
import { TransformComponent, TransformWrapper, useTransformContext } from 'react-zoom-pan-pinch';

function Menu(props){
    const { t } = useTranslation();
    const [pageNumber, setPageNumber] = useState(0);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [menu, setMenu] = useState(null);    
    const [menuPages, setMenuPages] = useState(null);
    const [pageEntry, setPageEntry] = useState(null);
    const [filteredAllergens, setFilteredAllergens] = useState("");
    const [pageHasAllergens, setPageHasAllergens] = useState(false);

    const language = browserLocales(true)[0] === "en" ? "en" : "fr";

    const windowDimensions = useContext(PlayerContext).get("windowDimensions");
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");

    const menuColor = useContext(PlayerContext).get("menuColor");
    const stickerContext = useContext(StickerContext);
    const titleFont = props.story.menuStyle === "custom" ? stickerContext.get("menuTitleFont") : null;
    const titleFontStyle = titleFont ? {fontFamily: titleFont} : {}; 
    const titleFontSizeMult = titleFont === "Bestro" ? 2 : 1;
    const textFont =  props.story.menuStyle === "custom" ? stickerContext.get("menuTextFont") : null;
    const textFontStyle = textFont ? {fontFamily: textFont} : {}; 
    const priceColorStyle = props.story.menuStyle === "custom" && menu && menu.priceColor ? {color: menu.priceColor} : {}; 
    const descColorStyle = props.story.menuStyle === "custom" && menu && menu.descColor ? {color: menu.descColor} : {}; 
    const playMode = useContext(PlayerContext).get("playMode");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier"); 
    const targetedElement = useRef(null);

    const [scrollPosition, setScrollPosition] = useState(0);

    const transformComponentRef = useRef(null);

    useEffect(() => {  
        if (pageEntry == null && scrollPosition !== 0){
            let menuContainer = document.getElementById("menu-container");
            if (menuContainer != null){
                menuContainer.scrollTo(0, scrollPosition);
                setScrollPosition(0);
            }
        }

    }, [scrollPosition, pageEntry]);   
    
    useEffect(() => {
        if (pageNumber && pageEntry == null){
            let menuContainer = document.getElementById("menu-container");
            if (menuContainer != null){
                menuContainer.scrollTo(0, 0);
                setScrollPosition(0);
            }
        }
    }, [pageNumber]);

    const minSwipeDistance = 80; 
    const onTouchStart = (e) => {        
        setTouchEnd(null);
        setTouchStart(e.targetTouches[0].clientX);
    }
    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);
    const onTouchEnd = (e) => {
        if (!touchStart || !touchEnd || !menuPages){
            setTouchStart(null);
            setTouchEnd(null);
            return;
        } 
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (isLeftSwipe) {
            if (pageNumber < menuPages.length - 1){
                setPageNumber(pageNumber + 1);
                analyticsManager.logSwipeFullMenuTag(props.storyId, playMode);
                if (window.ttq){
                    window.ttq.track('ViewContent', {                
                        "contents": [
                            {              
                                "content_id": "0",           
                                "content_name": "change menu page for " + (pageNumber + 1),
                            }
                        ],
                    });
                }      
                if (window.fbq){                    
                    window.fbq('track', 'ViewContent', {"content_name": "change menu page for " + (pageNumber + 1)});
                }         
            }
        }
        else if (isRightSwipe){
            if (pageNumber > 0){
                setPageNumber(pageNumber - 1);
                analyticsManager.logSwipeFullMenuTag(props.storyId, playMode);
                if (window.ttq){
                    window.ttq.track('ViewContent', {                
                        "contents": [
                            {              
                                "content_id": "0",           
                                "content_name": "change menu page for " + (pageNumber - 1),
                            }
                        ],
                    });
                }
                if (window.fbq){                    
                    window.fbq('track', 'ViewContent', {"content_name": "change menu page for " + (pageNumber - 1)});
                }
            }
        }
        setTouchStart(null);
        setTouchEnd(null);
    };    

    // TODO mutualize
    // disable swipe to close on facebook browser on iOS
    let firstClientX, clientX;
    let firstClientY, clientY;
    const preventTouch = (e) => {        
        clientX = e.touches[0].clientX - firstClientX;
        clientY = e.touches[0].clientY - firstClientY;

        if (Math.abs(clientX) > Math.abs(clientY)) {
            e.preventDefault();
            e.returnValue = false;

            return false;
        }
    };
    const touchStartFB = (e) => {
        firstClientX = e.touches[0].clientX;
        firstClientY = e.touches[0].clientY;
    };
    const containerRef = useRef(window);
    useEffect(() => {
        if (containerRef.current === null || ! (isFacebookInAppBrowser() && isIOS())) {
            return;
        }
        if (! props.show) {
            try {
                containerRef.current.removeEventListener('touchstart', touchStartFB);
                containerRef.current.removeEventListener('touchmove', preventTouch, { passive: false });
            } catch (e) {}
        }

        containerRef.current.addEventListener('touchstart', touchStartFB);
        containerRef.current.addEventListener('touchmove', preventTouch, { passive: false });
    }, [containerRef, props.show]);
    
    useEffect(() => {
        if (props.story.menuId != null){
            getMenuPages(props.story.menuId, pageNumber);            
        }
        else if ((playMode === "preview" || playMode === "demo" || props.storyId === "cafeupreality" || props.storyId === "cafeuprealityad") && menuPages == null){
            let demoPages = playMode === "preview" ? getMakerDemoProductPages() : getDemoProductPages();
            setMenuPages(demoPages);
            setMenu({menuPages: demoPages});
        }
      }, [props.story.menuId, pageNumber]);

    async function getMenuPages(menuId, pageNumber){   
        let localeMenu = menu;     
        if (!menu){
            const db = getFirestore();
            const menuRef = doc(db, "menus", menuId);
            let menuDoc = await getDoc(menuRef).catch((error) => {                
                Sentry.captureMessage("menu initial fetch failed, retrying " + printObj(error));
            });
            if (!menuDoc){                
                menuDoc = await getDoc(menuRef).catch((error) => {                
                    Sentry.captureMessage("menu retry failed " + printObj(error));
                });
            }
            localeMenu = menuDoc.data();
            setMenu(localeMenu);
        }
              
        const localMenuPages = menuPages == null ? [] : [...menuPages];
        if (pageNumber === 0 && localeMenu.menuPages.length > 0 && localMenuPages.length === 0){
            let menuPage = await getMenuPage(localeMenu.menuPages[0]);
            if (!menuPage){
                menuPage = await getMenuPage(localeMenu.menuPages[0]);
            }
            localMenuPages.push(menuPage);
        }
        if (localeMenu.menuPages.length > pageNumber+1 && localMenuPages.length < pageNumber+2) {
            let menuPage = await getMenuPage(localeMenu.menuPages[pageNumber+1]); // pas sync
            if (!menuPage){
                menuPage = await getMenuPage(localeMenu.menuPages[pageNumber+1]);
            }
            localMenuPages.push(menuPage);
            if (menuPage.pageTypeImg){
                try {
                    fetch(menuPage.pageTypeImg).catch((error) => {
                        // Nothing
                    });
                }
                catch(error){
                    // Nothing
                }
            }
        }
        
        setMenuPages(localMenuPages);   
        if (localMenuPages[pageNumber] && localMenuPages[pageNumber].pageEntries)     
            setPageHasAllergens(hasAllergens(localMenuPages[pageNumber].pageEntries));
    }

    async function getMenuPage(menuPageId){
        const db = getFirestore();
        const menuPageRef = doc(db, "menu_pages", menuPageId);
        const menuPage = await getDoc(menuPageRef).catch((error) => {                
            Sentry.captureMessage("menu page fetch failed " + printObj(error));
        });
        
        return menuPage.data();
    }

    let marginBtwTopTitle = 0;
    if (props.story.menuStyle !== "modern"){
        marginBtwTopTitle = (props.story.menuStyle !== "custom" ? 36 : 28) * miniMultiplier;
    }
    else if (props.story.menuStyle === "modern"){
        marginBtwTopTitle = 52 * miniMultiplier;
    }

    const textColorStyle = props.story.menuStyle === "custom" && menu && menu.textColor ? {color: menu.textColor} : {};

    const title = () => {     
        if (!menuPages || !menuPages[pageNumber]) return null;
        
        let titleStyle: CSSProperties = {};
        let imgSrc = "";
        let imgStyle: CSSProperties = {};       
        let titleSuffix = "";
        let textWidthAddition = 0;
        
        let pageTypeText = "";
        if (menuPages[pageNumber].pageType != null && menuPages[pageNumber].pageType.fr){            
            pageTypeText = menuPages[pageNumber].pageType[language];                        
        }

        if (pageTypeText.length < 8)
            textWidthAddition = 30;
        
        switch (props.story.menuStyle){
            case "elegant":
                titleStyle = {color: '#404040', fontSize: 44 * miniMultiplier, fontFamily: 'Alex brush'};
                imgSrc = "/images/separatorElegant.png";
                // TODO make it proportional to text length
                imgStyle = {marginTop: 10 * miniMultiplier, width: 120 * miniMultiplier, height:8 * miniMultiplier};
                break;
            case "friendly":
                titleStyle = {color: '#404040', fontSize: 36 * miniMultiplier, fontFamily: 'Roboto condensed', fontWeight: 'bold'};
                imgSrc = "/images/separatorFriendly.png";
                titleSuffix = " " + getEmojiFromPageType(menuPages[pageNumber].pageType.en);
                imgStyle = {marginTop: 10 * miniMultiplier, width: 100 * miniMultiplier, height:16 * miniMultiplier};                
                break;
            case "modern":
                titleStyle = {color: '#404040', fontSize: 36 * miniMultiplier, fontFamily: 'Roboto condensed', fontWeight: 'bold'};
                imgSrc = "";
                imgStyle = {};
                break;
            case "simple":                
                titleStyle = {color: '#404040', fontSize: 36 * miniMultiplier, fontFamily: 'Roboto'};
                imgSrc = "/images/separatorSimple.png";
                imgStyle = {marginTop: 10 * miniMultiplier, width: getTextWidth(pageTypeText, 36 * miniMultiplier + "px Roboto"), height:3 * miniMultiplier};
                break;
            case "custom":
                titleStyle = {color: '#404040', fontSize: 36 * miniMultiplier * titleFontSizeMult, fontFamily: titleFont ? titleFont : 'Roboto'};
                imgSrc = "";
                imgStyle = {};
                break;
        }

        if (props.story.menuStyle !== "modern" && props.story.menuStyle !== "custom"){                       
            return (
                <div className="menu-page-title-container" style={{marginTop: marginBtwTopTitle}}>
                    <div style={{...titleStyle, ...textColorStyle}}>{menuPages[pageNumber].pageType[language]}{titleSuffix}</div>
                    {menuPages[pageNumber].pageType[language] != null && <Image source={imgSrc} style={{...imgStyle}} tintColor={menuColor}/>}
                </div> 
            );
        }
        else if (props.story.menuStyle !== "custom"){               
            const titleSizeRoboto = getTextWidth(pageTypeText + textWidthAddition, 36 * miniMultiplier + "px Roboto condensed");      
            const separatorLeftX = 0;
            const separatorRightX = separatorLeftX + titleSizeRoboto + (27 - 92 + 27) * miniMultiplier;            
            
            return (
                <div className='menu-page-title-container' style={{marginTop: marginBtwTopTitle, }}>
                    <div style={{height: menuPages[pageNumber].pageType[language] != null ? 66 * miniMultiplier : 0,}}/>
                    <div style={{position: 'absolute', width: titleSizeRoboto + 2 * 27 * miniMultiplier, height: 66 * miniMultiplier}}>
                        <div className='menu-page-title' style={{...titleStyle, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', whiteSpace: 'nowrap', ...textColorStyle}}>{pageTypeText}</div>
                        {
                            menuPages[pageNumber].pageType[language] != null && 
                                <>
                                    <Image source="/images/separatorModernLeftLittle.png" style={{left: separatorLeftX, height: 66 * miniMultiplier, width: 92 * miniMultiplier, position:'absolute', top: '50%', transform: 'translate(0%, -50%)'}} tintColor={menuColor}/>
                                    <Image source="/images/separatorModernRightLittle.png" style={{left: separatorRightX, height: 66 * miniMultiplier, width: 92 * miniMultiplier, position:'absolute', top: '50%', transform: 'translate(0%, -50%)'}} tintColor={menuColor}/>                            
                                </>
                        }
                    </div> 
                </div>                
            );
        }
        else {
            return (
                <div className="menu-page-title-container" style={{marginTop: marginBtwTopTitle}}>
                    <div style={{...titleStyle, display: 'flex', flexDirection: 'row',  alignItems: 'center', paddingLeft: 24 * miniMultiplier, paddingRight: 24 * miniMultiplier, textAlign: 'center', ...textColorStyle}}>{pageTypeText}{menuPages[pageNumber].pageTypeImg && <img src={menuPages[pageNumber].pageTypeImg} style={{marginLeft: 8, width: 36 * miniMultiplier * titleFontSizeMult * 0.9, height: 36 * miniMultiplier * titleFontSizeMult * 0.9}} />}</div>                    
                </div> 
            );
        }
    };

    function hasAllergens(pageEntries){
        let hasAllergens = false;
        for (const pageEntry of pageEntries){            
            if (pageEntry.entries){                
                for (const entry of pageEntry.entries){
                    if (entry.allergens){
                        hasAllergens = true;
                        break;
                    }
                }
            }            
        }
        return hasAllergens;
    }

    function hasNoSelectedAllergens(entry){                
        let showEntry = true;
        if (entry.allergens){             
            const allergensTable = filteredAllergens.split(",");
            for (const allergen of allergensTable){
                if (allergen !== "" && entry.allergens.toLowerCase().includes(allergen.trim().toLowerCase())){
                    showEntry = false;
                    break;
                }
            }
        }

        return showEntry;
    }
    
    let hideDescriptionPrice = false;
    if (props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds"){
        hideDescriptionPrice = true;
    }

    const entryImageDimensions = 72 * miniMultiplier;
    let listItems = [];
    let allergensAlreadyDisplayed = false;
    if (menuPages != null && menuPages[pageNumber]){ 
        if (menuPages[pageNumber] && menuPages[pageNumber].pageType && menuPages[pageNumber].pageType.fr === "image"){
            listItems[0] = <>
                    <div className="FullScreenContainer" style={{backgroundColor: menuPages[pageNumber].pageBackground}}>              
                        <TransformWrapper ref={transformComponentRef}>
                            <TransformComponent>
                                <img alt="full screen menu page" src={menuPages[pageNumber].pageImgUrl} style={{objectFit: "contain", width: storyDimensions.containerWidth, height: storyDimensions.containerHeight}}/>                         
                            </TransformComponent>
                        </TransformWrapper>                              
                    </div>
                </>;
        }  
        else {              
            listItems = menuPages[pageNumber].pageEntries && menuPages[pageNumber].pageEntries.map((pageEntry) =>{                         
                
                if (pageEntry.entryType === "startingNote" || pageEntry.entryType === "endingNote"){  
                    const textColumnWidth = storyDimensions.containerWidth - 48 * miniMultiplier;
                    const noteOn2Lines = getTextWidth(pageEntry.text[language], "" + 14 * miniMultiplier + "px " + (textFont ? textFont : "Roboto")) > storyDimensions.containerWidth - 2 * 24 * miniMultiplier;

                    let marginTop = 10 * miniMultiplier;
                    let marginBottom = 10 * miniMultiplier;
                    if (pageEntry.entryType === "startingNote"){ 
                        if (props.story.menuStyle === "modern"){
                            marginTop = - 10 * miniMultiplier;
                            marginBottom = 30 * miniMultiplier;
                        }
                        else  {
                            marginTop = - 36 * miniMultiplier;
                            marginBottom = 30 * miniMultiplier;
                        }
                    }            

                    return (
                        <div style={{width: textColumnWidth, textAlign: noteOn2Lines ? 'start' : 'center', fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginTop: marginTop, marginBottom: marginBottom, ...textFontStyle, ...textColorStyle}}>
                            {pageEntry.text[language]}
                        </div>
                    ); 
                }    
                else if (pageEntry.entryType === "dishList" || pageEntry.entryType.fr){
                    const startingNoteOn2Lines = pageEntry.startingNote && getTextWidth(pageEntry.startingNote[language], "" + 14 * miniMultiplier + "px " + (textFont ? textFont : "Roboto")) > storyDimensions.containerWidth - 2 * 24 * miniMultiplier;
                    const endingNoteOn2Lines = pageEntry.endingNote && getTextWidth(pageEntry.endingNote[language], "" + 14 * miniMultiplier + "px " + (textFont ? textFont : "Roboto")) > storyDimensions.containerWidth - 2 * 24 * miniMultiplier;

                    const startingNoteCentering = startingNoteOn2Lines ? 'start' : 'center';
                    const endingNoteCentering = endingNoteOn2Lines ? 'start' : 'center';
                    
                    const notFirstPageEntry = menuPages[pageNumber].pageEntries.findIndex((pEntry)=>pEntry.entriesTitle === pageEntry.entriesTitle) > 0;

                    let showAllergensSelector = ! allergensAlreadyDisplayed;
                    allergensAlreadyDisplayed = true;                

                    if (pageEntry.entries){

                        return (
                            <div>
                                {pageHasAllergens && showAllergensSelector &&
                                    <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                                        <AllergensSelect color={props.story.primaryColor} callback={(values)=> {setFilteredAllergens(values)}}/>
                                    </div>
                                } 
                                {pageEntry.entriesTitle != null && <div style={{marginTop: notFirstPageEntry ? 10 * miniMultiplier : 0, marginBottom: 20 * miniMultiplier, fontSize: 24 * miniMultiplier, color: '#404040', textAlign: 'center', ...textFontStyle, ...textColorStyle}}>{pageEntry.entriesTitle[language]}</div>}
                                {pageEntry.startingNote != null && 
                                    <div style={{width: storyDimensions.containerWidth - 48 * miniMultiplier, textAlign: startingNoteCentering, fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginBottom: 20 * miniMultiplier, ...textFontStyle, ...textColorStyle}}>
                                        {pageEntry.startingNote[language]}
                                    </div>
                                }                                               
                                {pageEntry.entries && pageEntry.entries.map((entry) => {
                                        const textColumnWidth = storyDimensions.containerWidth - 48 * miniMultiplier - (entry.entryImage != null ? (entryImageDimensions + 12 * miniMultiplier) : 0);                       

                                        function openMealDetail(){
                                            let menuContainer = document.getElementById("menu-container");
                                            if (menuContainer != null){
                                                setScrollPosition(menuContainer.scrollTop);
                                                analyticsManager.logOpenDishDetalTag(props.storyId, playMode, true);
                                                if (window.ttq){
                                                    window.ttq.track('ViewContent', {                
                                                        "contents": [
                                                            {              
                                                                "content_id": "0",           
                                                                "content_name": "open menu detail for " + entry.entryTitle[language],
                                                            }
                                                        ],
                                                    });
                                                }
                                                if (window.fbq){                    
                                                    window.fbq('track', 'ViewContent', {"content_name": "open menu detail for " + entry.entryTitle[language]});
                                                }
                                                setPageEntry(entry);
                                            }
                                        }

                                        const img = () => {
                                            if (entry.entryImage != null){
                                                return (                                            
                                                    <img src={entry.entryImage} onLoad={(e) => e.target.style.color='white'} onError={(e) => e.target.style.color='transparent'} style={{marginLeft: 12 * miniMultiplier, width: entryImageDimensions, height: entryImageDimensions, objectFit: 'cover', borderRadius: 20, pointerEvents: 'none'}} alt={isFirefox() ? "":"thumbnail of a dish"} />
                                                );
                                            }
                                            else return null;
                                        };                              

                                        const separator = () => {
                                            if (entry.entryId !== menuPages[pageNumber].pageEntries[menuPages[pageNumber].pageEntries.length -1].entryId){
                                                return (
                                                    <Image alt="line separator" source="/images/menuSeparator.png" style={{marginTop: 20 * miniMultiplier, marginBottom: 20 * miniMultiplier, width: 180 * miniMultiplier, height: 1}} tintColor={menuColor}/>
                                                );
                                            }
                                            else return null;            
                                        };   
                                        
                                        const showEntry = hasNoSelectedAllergens(entry);

                                        if (showEntry){                                        

                                            function onLineTouchStartListener(e){                                            
                                                if (entry.entryImage != null)
                                                    targetedElement.current = {element: e.target, x: e.touches[0].clientX, y: e.touches[0].clientY};;                                           
                                            }

                                            function onLineTouchMoveListener(e){
                                                if (entry.entryImage != null){
                                                    targetedElement.current.lastX = e.touches[0].clientX;      
                                                    targetedElement.current.lastY = e.touches[0].clientY; 
                                                    targetedElement.current.hasMove = true;     
                                                }
                                            }
                                        
                                            function onLineTouchEndListener(e){                                            
                                                if(entry.entryImage != null && targetedElement.current.element === e.target && !targetedElement.current.hasMove /*(targetedElement.current.lastX ? Math.abs(targetedElement.current.lastX - targetedElement.current.x) < 50 : true) && (targetedElement.current.lastY ? Math.abs(targetedElement.current.lastY - targetedElement.current.y) < 50 : true)*/){
                                                    openMealDetail();
                                                };      
                                                targetedElement.current = null;
                                            }

                                            return(                   
                                                <div key={pageNumber + "-" + entry.entryId} className='menu-line-sep-container' >
                                                    <div className='menu-line-container' onTouchStart={(e)=>onLineTouchStartListener(e)} onTouchMove={(e)=>onLineTouchMoveListener(e)} onTouchEnd={(e)=>onLineTouchEndListener(e)} onClick={()=>{if (entry.entryImage != null) openMealDetail();}}>
                                                        <div className='menu-line-container-wo-img' style={{width: textColumnWidth, /*height: entryImageDimensions*/}} >                                                      
                                                            <div className="menu-entry-title" style={{width: textColumnWidth, textAlign: "start", fontSize: (entry.entrySubtitle ? 18 : 16) * miniMultiplier, color: '#404040', ...textFontStyle, ...textColorStyle}}>
                                                                {entry.entryTitle[language]}
                                                            </div>
                                                            {entry.entrySubtitle != null && !hideDescriptionPrice && 
                                                                <div className="menu-entry-subtitle" style={{whiteSpace: 'pre-line', width: textColumnWidth, /*height: 34 * miniMultiplier,*/ textAlign: "start", fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginTop: 4 * miniMultiplier, ...textFontStyle, ...descColorStyle}}>{entry.entrySubtitle[language]}</div>
                                                            }
                                                            {entry.entryPrice != null && !hideDescriptionPrice && entry.entryPrice[language] && 
                                                                <div style={{width: textColumnWidth, textAlign: 'end', fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginTop: 4 * miniMultiplier, ...priceColorStyle, ...textFontStyle}}>
                                                                    {    
                                                                    entry.entryPrice[language].map((price) => {
                                                                        const sep = entry.entryPrice[language].indexOf(price) !== entry.entryPrice[language].length - 1 ? " - " : "";
                                                                        return <>{price + sep}</>
                                                                    })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        {img()}              
                                                    </div>  
                                                    {separator()}
                                                </div>              
                                            ); 
                                        }
                                        else {
                                            return null;
                                        }
                                    
                                    })
                                }  
                                {pageEntry.endingNote != null && 
                                    <div style={{width: storyDimensions.containerWidth - 48 * miniMultiplier, textAlign: endingNoteCentering, fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginBottom: 20 * miniMultiplier, ...textFontStyle, ...descColorStyle}}>
                                        {pageEntry.endingNote[language]}
                                    </div>
                                }
                            </div> 
                        );  
                    }    
                    else {
                        return null;
                    }     
                }
                else if (pageEntry.entryType === "formula"){
                    const startingNoteOn2Lines = pageEntry.startingNote && getTextWidth(pageEntry.startingNote[language], "" + 14 * miniMultiplier + "px " + (textFont ? textFont : "Roboto")) > storyDimensions.containerWidth - 2 * 24 * miniMultiplier;
                    const endingNoteOn2Lines = pageEntry.endingNote && getTextWidth(pageEntry.endingNote[language], "" + 14 * miniMultiplier + "px " + (textFont ? textFont : "Roboto")) > storyDimensions.containerWidth - 2 * 24 * miniMultiplier;

                    const startingNoteCentering = startingNoteOn2Lines ? 'start' : 'center';
                    const endingNoteCentering = endingNoteOn2Lines ? 'start' : 'center';

                    const notFirstPageEntry = menuPages[pageNumber].pageEntries.findIndex((pEntry)=>pEntry.entriesTitle === pageEntry.entriesTitle) > 0;

                    let showAllergensSelector = ! allergensAlreadyDisplayed;
                    allergensAlreadyDisplayed = true; 

                    function formatFormula(){
                        if (!pageEntry.entriesTitle[language]) return;

                        const delimitor = language === "en" ? " or " : " ou ";                    
                        const lowerOverUpperCaseDelimitor = (pageEntry.entriesTitle[language].match(new RegExp(delimitor, "g")) || []).length > (pageEntry.entriesTitle[language].match(new RegExp(delimitor.toUpperCase(), "g")) || []).length                    
                        const usedDelimitor = language === "en" ? (lowerOverUpperCaseDelimitor ? " or " : " OR ") : (lowerOverUpperCaseDelimitor ? " ou " : " OU ");                 
                        const formulaChunks = pageEntry.entriesTitle[language].split(usedDelimitor);

                        const formattedFormula = formulaChunks.map((chunk)=>{
                            const idx = formulaChunks.indexOf(chunk);
                            if (idx > 0 && idx < formulaChunks.length){ 
                                return (<><br/>{usedDelimitor}<br/>{chunk.trim()}</>);
                            }
                            else
                                return chunk.trim();
                        });

                        return formattedFormula;
                    }

                    return (
                        <div>
                            {pageHasAllergens && showAllergensSelector && 
                                <div style={{width: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 20}}>
                                    <AllergensSelect color={props.story.primaryColor} callback={(values)=> {setFilteredAllergens(values)}}/>
                                </div>
                            } 
                            {pageEntry.entriesTitle != null && 
                                <div style={{marginTop: notFirstPageEntry ? 10 * miniMultiplier : 0, marginBottom: 20 * miniMultiplier, textAlign: 'center', fontSize: 24 * miniMultiplier, color: '#404040', ...textColorStyle}}>
                                    {formatFormula()}
                                    {pageEntry.formulaPrice &&
                                        <div style={{marginTop: 10 * miniMultiplier, marginBottom: 0, textAlign: 'center', fontSize: 20 * miniMultiplier, color: '#404040', ...textFontStyle, ...priceColorStyle}}>
                                            {pageEntry.formulaPrice}
                                        </div>
                                    }
                                </div>
                            }
                            {pageEntry.startingNote != null && 
                                <div style={{width: storyDimensions.containerWidth - 48 * miniMultiplier, textAlign: startingNoteCentering, fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginBottom: 30 * miniMultiplier, ...textFontStyle, ...textColorStyle}}>
                                    {pageEntry.startingNote[language]}
                                </div>
                            }
                            {pageEntry.entries && pageEntry.entries.map((entry) => {
                                    
                                    if (entry.entryTitle != null){
                                        const textColumnWidth = storyDimensions.containerWidth - 48 * miniMultiplier;

                                        const separator = () => {
                                            const entriesNb = pageEntry.entries.length;
                                            const notLastPosition = entry.entryId !== entriesNb - 1;
                                            const nextPositionIsNotSeparator = entry.entryId < entriesNb - 1 && pageEntry.entries[entry.entryId + 1].separator !== true;                                    
                                                                                    
                                            if (notLastPosition && nextPositionIsNotSeparator){
                                                return (
                                                    <Image source="/images/menuSeparator.png" style={{marginTop: 20 * miniMultiplier, marginBottom: 20 * miniMultiplier, width: 60 * miniMultiplier, height: 2}} tintColor={menuColor}/>
                                                );
                                            }
                                            else if (! notLastPosition){
                                                return (
                                                    <div style={{marginTop: 20 * miniMultiplier}} />
                                                );
                                            }
                                            else return null;            
                                        };                                    
                                        
                                        return(                   
                                            <div key={pageNumber + "-" + entry.entryId} className='menu-line-sep-container' >
                                                <div className='menu-line-container'>
                                                    <div className='menu-line-container-wo-img' style={{width: textColumnWidth, /*height: entryImageDimensions*/}}>                                                      
                                                        <div className="menu-entry-title" style={{width: textColumnWidth, textAlign: "center", fontSize: (entry.entrySubtitle ? 18 : 16) * miniMultiplier, color: '#404040', ...textFontStyle, ...textColorStyle}}>
                                                            {entry.entryTitle[language]}
                                                        </div>
                                                        {entry.entrySubtitle && <div className="menu-entry-subtitle" style={{whiteSpace: 'pre-line', width: textColumnWidth, /*height: 34 * miniMultiplier,*/ textAlign: "center", fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginTop: 4 * miniMultiplier, ...textFontStyle}}>{entry.entrySubtitle[language]}</div>}
                                                    </div>       
                                                </div>  
                                                {separator()}
                                            </div>              
                                        );
                                    } 
                                    else if (entry.separator === true){
                                        return (
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                                <Image source="/images/menuSeparatorOpaque.png" style={{marginTop: 20 * miniMultiplier, marginBottom: 20 * miniMultiplier, width: (storyDimensions.containerWidth - 48 * miniMultiplier) * 0.75, height: 2}} tintColor={menuColor}/>
                                            </div>
                                        );
                                    }
                                
                                })
                            }  
                            {pageEntry.endingNote != null && 
                                <div style={{width: storyDimensions.containerWidth - 48 * miniMultiplier, textAlign: endingNoteCentering, fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginTop: 10, marginBottom: 30 * miniMultiplier, ...textFontStyle, ...descColorStyle}}>
                                    {pageEntry.endingNote[language]}
                                </div>
                            }
                        </div> 
                    );
                }            
                else if (pageEntry.entryType === "note"){                  
                    const centering = pageEntry.centering != null ? pageEntry.centering : 'center';

                    function formatNoteText(){    
                        if (!pageEntry.text[language]) return;
                        
                        const formulaChunks = pageEntry.text[language].split("\\n");
                        const formattedText = formulaChunks.map((chunk)=>{
                            const idx = formulaChunks.indexOf(chunk);
                            if (idx > 0 && idx < formulaChunks.length){ 
                                return (<><br/>{chunk.trim()}</>);
                            }
                            else
                                return chunk.trim();
                        });

                        return formattedText;
                    }

                    return(
                        <div style={{marginTop: 0}}>
                            <div style={{width: storyDimensions.containerWidth - 48 * miniMultiplier, whiteSpace: 'pre-line', textAlign: centering, fontSize: 14 * miniMultiplier, fontStyle: 'italic', color: '#707070', marginBottom: 30 * miniMultiplier, ...textFontStyle, ...descColorStyle}}>
                                {formatNoteText()}
                                {pageEntry.allergens && 
                                    <><br/><br/><a href={pageEntry.allergens} target="_blank">{t('MenuAllergens')}</a></>
                                }
                            </div>
                            {props.story.avatarUrl &&
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                    <img alt="restaurant's avatar" src={props.story.avatarUrl} className="menu-logo-container" style={{borderColor: props.story.primaryColor + "28"}}/>                            
                                </div> 
                            }
                        </div>
                    );
                }                
            });
        }
    }

    const borders = () => {
        if (props.story.menuStyle === "elegant"){
            return (
                <div>
                    <Image source="/images/elegant_border.png" style={{position: 'absolute', top:0, left: 0, width: 80 * miniMultiplier, height: 80 * miniMultiplier, transform: 'scaleX(-1)', WebkitTransform: 'scaleX(-1)', opacity:0.4}} tintColor={menuColor}/>
                    <Image source="/images/elegant_border.png" style={{position: 'absolute', top:0, right: 0, width: 80 * miniMultiplier, height: 80 * miniMultiplier, opacity:0.4}} tintColor={menuColor}/>                    
                </div>
            );
        }
        else return null;    
    };      

    const marginBtwTitleList = props.story.menuStyle === "modern" && menuPages != null ? (((36 + 66) * miniMultiplier + menuPages[pageNumber].pageEntries.length * 72 * miniMultiplier + (menuPages[pageNumber].pageEntries.length - 1) * 41 * miniMultiplier + 20 * miniMultiplier) > (storyDimensions.containerHeight ? 112 : 46) * miniMultiplier) : 56 * miniMultiplier;    

    if (props.show){  
        // scroll up closes the webpage otherwise disallowing us to scroll up the page before the snapchat browser scroll up to close feature
        // overflow visible allow to make the page scrollable, then we set it to hidden on menu close (setting back to auto would make the story video scrollable) 
        if (isSnapchatInAppBrowser() && isAndroid() || isIOS() && isInstagramInAppBrowser() || isIOS() && isSnapchatInAppBrowser()){                
            document.getElementsByClassName("StoryVideo")[0].style.display = "none";
            const shadows = document.getElementById("menu-shadows");
            if (shadows) shadows.style.display = "none";
            document.getElementsByClassName("StoryVideoContainer")[0].style.overflow = "visible";  
            if (isIOS() && isSnapchatInAppBrowser()){
                document.getElementsByTagName('body')[0].style.backgroundColor = "#FFFFFF";
                const blockingOverlays = document.getElementsByClassName("BlockingOverlay");                
                if (blockingOverlays && blockingOverlays[0])
                    blockingOverlays[0].style.display = "none"; 
                const galleryContainer = document.getElementById("gallery-container");
                if (galleryContainer){
                    galleryContainer.style.display = "none";
                }                
            }              
        }     
        
        let additionalStyle = {};
        // on iOS with Snapchat we force a standard positionning to avoid scrolling up to close the web page
        if (! (isIOS() && isSnapchatInAppBrowser())){
            additionalStyle = {
                position: 'absolute',
                overflowX: 'hidden', 
                overflowY: 'auto'
            };
        }
        if (props.story.menuStyle === "custom" && menu && menu.backgroundColor){
            additionalStyle.backgroundColor = menu.backgroundColor;
        }

        const listItemsMarginTop = marginBtwTitleList + (props.story.menuStyle === "modern" ? 30 : 0);             

        const swipeToTurn = !(isTikTokInAppBrowser() && isIOS() && !isAndroid() || isFirefox() && isAndroid() || isIpad() && isInstagramInAppBrowser());

        let closeColor = menuColor;
        if (props.story.menuStyle === "custom" && menu && menu.closeColor && !pageEntry){
            closeColor = menu.closeColor;
        }
        if (props.story.menuStyle === "custom" && menu && menu.closeColor && pageEntry){
            closeColor = menu.closeColor;
        }
        if (props.story.menuStyle === "custom" && menu && menu.detailCloseColor && pageEntry){
            closeColor = menu.detailCloseColor;
        }

        const isImagePage = menuPages && menuPages[pageNumber] && menuPages[pageNumber].pageType.fr && menuPages[pageNumber].pageType.fr === "image";

        return ( 
            <div style={{pointerEvents: 'auto'}}>                 
                {  !pageEntry &&         
                    <div id="menu-container" className={'menu-container' + ((checkMobile() || playMode === "demo") ? ' menu-container-scroll-mobile' : ' menu-container-scroll-desktop')} style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, ...additionalStyle}} onTouchStart={swipeToTurn ? onTouchStart : null} onTouchMove={swipeToTurn ? onTouchMove : null} onTouchEnd={swipeToTurn ?  onTouchEnd : null} >                                                                
                        {playMode === "preview" && !checkMobile() && <div style={{width: 1, height: storyDimensions.containerHeight, backgroundColor: '#b2ede7', position: 'absolute', left: 0, bottom: 0}} />}                 
                        {borders()}
                        {!isImagePage && title()}                                       
                        <div className="menu-items" style={{marginTop: menuPages && menuPages[pageNumber] && menuPages[pageNumber].pageType.fr && menuPages[pageNumber].pageType.fr !== "" && !isImagePage ? listItemsMarginTop : 0, marginLeft: !isImagePage ? 24 * miniMultiplier : 0, marginBottom: !isImagePage ? 60 * miniMultiplier : 0, width: !isImagePage ? storyDimensions.containerWidth - 48 * miniMultiplier : storyDimensions.containerWidth, marginRight: !isImagePage ? 24 * miniMultiplier : 0, }}>                    
                            {listItems}
                        </div>                                                               
                    </div>   
                }  
                <MealDetail story={props.story} storyId={props.storyId} pageEntry={pageEntry} setPageEntry={setPageEntry} menuColor={props.story.menuColor} storyDimensions={storyDimensions} menu={menu} closeMenuColor={closeColor} close={closeMenuOrDetail}/>
                
                {!pageEntry &&(pageNumber > 0) && 
                        <div style={{position: 'absolute', zIndex: 30, pointerEvents: "auto", top: storyDimensions.containerHeight - 48 * miniMultiplier - 16 * miniMultiplier, left: storyDimensions.containerWidth/2 - (64+8) * miniMultiplier, }}>
                            <IconButton aria-label="page left" onClick={() => {
                                setPageNumber(pageNumber - 1);
                                analyticsManager.logSwipeFullMenuTag(props.storyId, playMode);
                                if (window.ttq){
                                    window.ttq.track('ViewContent', {                
                                        "contents": [
                                            {              
                                                "content_id": "0",           
                                                "content_name": "change menu page for " + (pageNumber - 1),
                                            }
                                        ],
                                    });
                                }
                                if (window.fbq){                    
                                    window.fbq('track', 'ViewContent', {"content_name": "change menu page for " + (pageNumber - 1)});
                                }
                                if (isImagePage){
                                    transformComponentRef.current.resetTransform();
                                }
                                }}>
                                <ArrowCircleLeft style={{color: menuColor, width: 48 * miniMultiplier, height: 48 * miniMultiplier}}/>
                            </IconButton>
                        </div> 
                }
               {!pageEntry && menu && (pageNumber < menu.menuPages.length - 1) && 
                        <div style={{position: 'absolute', zIndex: 30, pointerEvents: "auto", top: storyDimensions.containerHeight - 48 * miniMultiplier - 16 * miniMultiplier, left: storyDimensions.containerWidth/2 + 8 * miniMultiplier, }}>
                            <IconButton aria-label="page right" onClick={() => {
                                setPageNumber(pageNumber + 1);
                                analyticsManager.logSwipeFullMenuTag(props.storyId, playMode);
                                if (window.ttq){
                                    window.ttq.track('ViewContent', {                
                                        "contents": [
                                            {              
                                                "content_id": "0",           
                                                "content_name": "change menu page for " + (pageNumber + 1),
                                            }
                                        ],
                                    });
                                }
                                if (window.fbq){                    
                                    window.fbq('track', 'ViewContent', {"content_name": "change menu page for " + (pageNumber + 1)});
                                }
                                if (isImagePage){
                                    transformComponentRef.current.resetTransform();
                                }
                                }}>
                                <ArrowCircleRight style={{color: menuColor, width: 48 * miniMultiplier, height: 48 * miniMultiplier}}/>
                            </IconButton>
                        </div>
                } 
                {!pageEntry && 
                    <div style={{position: 'absolute', zIndex: 30, pointerEvents: "auto", top: storyDimensions.containerHeight - 48 * miniMultiplier - 8 * miniMultiplier, left: storyDimensions.containerWidth - 4 * miniMultiplier - 48 * miniMultiplier - 8 * miniMultiplier, }}>
                        <CloseButton onClick={(e) => closeMenuOrDetail(e)} color={closeColor}/>
                    </div>   
                }                       
            </div>
        );
    }
    else {
        return null;        
    }   

    function closeMenuOrDetail(e){
        if (pageEntry != null){
            analyticsManager.logOpenDishDetalTag(props.storyId, playMode, false);
            setPageEntry(null);            
        }
        else closeMenu(e);
    }
    
    function closeMenu(e){        
        if (isSnapchatInAppBrowser() && isAndroid() || isIOS() && isInstagramInAppBrowser() || isIOS() && isSnapchatInAppBrowser()){                            
            document.getElementsByClassName("StoryVideoContainer")[0].style.overflow = "hidden"; 
            document.getElementsByClassName("StoryVideo")[0].style.display = "";
            const shadows = document.getElementById("menu-shadows");
            if (shadows) shadows.style.display = "";
            if (isIOS() && isSnapchatInAppBrowser()){                       
                document.getElementsByTagName('body')[0].style.backgroundColor = addOpacityToHexColor(props.story.primaryColor, 0.2);
                const blockingOverlays = document.getElementsByClassName("BlockingOverlay");                
                if (blockingOverlays && blockingOverlays[0])
                    blockingOverlays[0].style.display = ""; 
                const galleryContainer = document.getElementById("gallery-container");
                if (galleryContainer){
                    galleryContainer.style.display = "";
                }
            }
        }      
        props.closeMenu();        
    }

    function getDemoProductPages(){ 
        return [
            /*{
                pageType: {fr: "Menus", en: "Menus"},
                pageEntries: [  
                    {
                        entryType: "startingNote",
                        text: "starting note",
                        centering: 'center'
                    },                 
                    {
                        entryType: "formula",
                        entriesTitle: {
                            fr: t('MenuDemoMenusTitle', {menu: "A"}),
                            en: t('MenuDemoMenusTitle', {menu: "A"}),
                        },
                        startingNote: "starting note",                        
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 1}),
                                    en: t('MenuDemoStarterTitle', {nb: 1})
                                },
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 2}),
                                    en: t('MenuDemoStarterTitle', {nb: 2})
                                },
                            },
                            {
                                entryId: 2,
                                separator: true,
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 1}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 1})
                                },
                            }, 
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 2}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 2})
                                },
                            }, 
                            {
                                entryId: 5,
                                separator: true,
                            },
                            {
                                entryId: 6,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitle', {nb: 1}),
                                    en: t('MenuDemoDessertTitle', {nb: 1}),
                                },
                            }, 
                            {
                                entryId: 7,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitle', {nb: 2}),
                                    en: t('MenuDemoDessertTitle', {nb: 2}),
                                },
                            },                                 
                        ],    
                        endingNote: "ending note",                        
                    },
                    {
                        entryType: "formula",
                        entriesTitle: {
                            fr: t('MenuDemoMenusTitle', {menu: "B"}),
                            en: t('MenuDemoMenusTitle', {menu: "B"}),
                        },
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoMenusDesc1'),
                                    en: t('MenuDemoMenusDesc1'),
                                },                                
                            },
                        ]
                    },                                     
                    {
                        entryType: "endingNote",
                        text: "ending note",
                        centering: 'center'
                    },
                ]
            },  */    
            /*{
                pageType: {fr: "image", en: "image"}, 
                pageImgUrl: "https://storage.googleapis.com/upreality-prod-619c3.appspot.com/restaurants/WgpXG6xvvYUdpit8zuK1PDDmXNM2/dMS24cQaTXn47tuspxet/menu/Menu-reveillon-Caffe.png",
                pageBackground: "#9ECEC9",
            },  */   
            {
                pageType: {fr: "Entrées", en: "Starters"},                
                pageEntries: [
                    /*{
                        entryType: "startingNote",
                        text: "starting note",
                        centering: 'center'
                    },*/
                    {
                        entryType: "dishList",
                        /*entriesTitle: "Salades",
                        startingNote: "starting note",*/
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitleDemo1', {nb: 1}),
                                    en: t('MenuDemoStarterTitleDemo1', {nb: 1})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDescDemo1', {nb: 1}),
                                    en: t('MenuDemoStarterDescDemo1', {nb: 1})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitleDemo2', {nb: 2}),
                                    en: t('MenuDemoStarterTitleDemo2', {nb: 2})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDescDemo2', {nb: 2}),
                                    en: t('MenuDemoStarterDescDemo2', {nb: 2})
                                },  
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 2,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitleDemo3', {nb: 3}),
                                    en: t('MenuDemoStarterTitleDemo3', {nb: 3})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDescDemo3', {nb: 3}),
                                    en: t('MenuDemoStarterDescDemo3', {nb: 3})
                                }, 
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_terrine_campagne.jpg",
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitleDemo4', {nb: 4}),
                                    en: t('MenuDemoStarterTitleDemo4', {nb: 4})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDescDemo4', {nb: 4}),
                                    en: t('MenuDemoStarterDescDemo4', {nb: 4})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitleDemo5', {nb: 5}),
                                    en: t('MenuDemoStarterTitleDemo5', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDescDemo5', {nb: 5}),
                                    en: t('MenuDemoStarterDescDemo5', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_moules_marinieres.jpg",
                            },
                            /*{
                                entryId: 41,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 5}),
                                    en: t('MenuDemoStarterTitle', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 5}),
                                    en: t('MenuDemoStarterDesc', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 42,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 5}),
                                    en: t('MenuDemoStarterTitle', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 5}),
                                    en: t('MenuDemoStarterDesc', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 43,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 5}),
                                    en: t('MenuDemoStarterTitle', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 5}),
                                    en: t('MenuDemoStarterDesc', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 44,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 5}),
                                    en: t('MenuDemoStarterTitle', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 5}),
                                    en: t('MenuDemoStarterDesc', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 45,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 5}),
                                    en: t('MenuDemoStarterTitle', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 5}),
                                    en: t('MenuDemoStarterDesc', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },   */                 
                        ],
                        /*endingNote: "ending note",*/
                    },
                    /*{
                        entryType: "dishList",
                        entriesTitle: "Antipasti",
                        startingNote: "starting note",                        
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 1}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 1}),
                                entryPrice: {fr:["37.5 cl : X €", "75 cl : X €"],en:["37.5 cl : X €", "75 cl : X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 1,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 2}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 2}),                                
                            },
                            {
                                entryId: 2,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 3}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 3}),
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 3,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 4}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 4}),
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 4,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 5}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 5}),
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },                    
                        ],
                        endingNote: "ending note",                        
                    },
                    {
                        entryType: "endingNote",
                        text: "ending note",
                        centering: 'center'
                    },*/
                ]
            },
            {                
                pageType: {fr: "Plats", en: "Dishes"},
                pageEntries: [
                    {
                        entryType: "dishList",
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitleDemo1', {nb: 1}),
                                    en: t('MenuDemoMainCourseTitleDemo1', {nb: 1})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDescDemo1', {nb: 1}),
                                    en: t('MenuDemoMainCourseDescDemo1', {nb: 1})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_maincourse.jpg",
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitleDemo2', {nb: 2}),
                                    en: t('MenuDemoMainCourseTitleDemo2', {nb: 2})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDescDemo2', {nb: 2}),
                                    en: t('MenuDemoMainCourseDescDemo2', {nb: 2})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 2,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitleDemo3', {nb: 3}),
                                    en: t('MenuDemoMainCourseTitleDemo3', {nb: 3})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDescDemo3', {nb: 3}),
                                    en: t('MenuDemoMainCourseDescDemo3', {nb: 3})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_confit_canard.jpg",
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitleDemo4', {nb: 4}),
                                    en: t('MenuDemoMainCourseTitleDemo4', {nb: 4})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDescDemo4', {nb: 4}),
                                    en: t('MenuDemoMainCourseDescDemo4', {nb: 4})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitleDemo5', {nb: 5}),
                                    en: t('MenuDemoMainCourseTitleDemo5', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDescDemo5', {nb: 5}),
                                    en: t('MenuDemoMainCourseDescDemo5', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_burger_bacon.jpg",
                            },
                        ],
                    }
                ],
            },
            {
                pageType: {fr: "Desserts", en: "Desserts"},
                pageEntries: [
                    {
                        entryType: "dishList",
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitleDemo1', {nb: 1}),
                                    en: t('MenuDemoDessertTitleDemo1', {nb: 1})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDescDemo1', {nb: 1}),
                                    en: t('MenuDemoDessertDescDemo1', {nb: 1})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_dessert.jpg",
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitleDemo2', {nb: 2}),
                                    en: t('MenuDemoDessertTitleDemo2', {nb: 2})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDescDemo2', {nb: 2}),
                                    en: t('MenuDemoDessertDescDemo2', {nb: 2})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 2,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitleDemo3', {nb: 3}),
                                    en: t('MenuDemoDessertTitleDemo3', {nb: 3})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDescDemo3', {nb: 3}),
                                    en: t('MenuDemoDessertDescDemo3', {nb: 3})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_mousse_chocolat.jpg",
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitleDemo4', {nb: 4}),
                                    en: t('MenuDemoDessertTitleDemo4', {nb: 4})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDescDemo4', {nb: 4}),
                                    en: t('MenuDemoDessertDescDemo4', {nb: 4})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitleDemo5', {nb: 5}),
                                    en: t('MenuDemoDessertTitleDemo5', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDescDemo5', {nb: 5}),
                                    en: t('MenuDemoDessertDescDemo5', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_glace.jpg",
                            },
                     ],
                    },
                ],
            },
            /*{                
                pageType: {fr: "Vins", en: "Wines"},
                pageEntries: [
                    {
                        entryType: "dishList",
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 1}),
                                    en: t('WineDemoTitle', {nb: 1}),
                                },   
                                entryPrice: {fr:[t('WineDemoPrices')],en:[t('WineDemoPrices')]},
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 2}),
                                    en: t('WineDemoTitle', {nb: 2}),
                                }, 
                                entryPrice: {fr:[t('WineDemoPrices2')],en:[t('WineDemoPrices2')]},
                            },
                            {
                                entryId: 2,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 3}),
                                    en: t('WineDemoTitle', {nb: 3}),
                                }, 
                                entryPrice: {fr:[t('WineDemoPrices')],en:[t('WineDemoPrices')]},
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 4}),
                                    en: t('WineDemoTitle', {nb: 4}),
                                }, 
                                entryPrice: {fr:[t('WineDemoPrices2')],en:[t('WineDemoPrices2')]},
                            },
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 5}),
                                    en: t('WineDemoTitle', {nb: 5}),
                                }, 
                                entryPrice: {fr:[t('WineDemoPrices')],en:[t('WineDemoPrices')]},
                            },
                     ],
                    },
                ],
            },*/
            {                
                pageType: {fr: "Note", en: "Note"},
                pageEntries: [
                    {
                        entryType: "note",
                        text: {
                            fr: t('EndingPageDemoText'),
                            en: t('EndingPageDemoText'),
                        },
                        centering: 'start',
                    },
                ]
            },
        ];
    }

    function getMakerDemoProductPages(){ 
        return [
            {
                pageType: {fr: "Menus", en: "Menus"},
                pageEntries: [  
                    /*{
                        entryType: "startingNote",
                        text: "starting note",
                        centering: 'center'
                    },  */                
                    {
                        entryType: "formula",
                        entriesTitle: {
                            fr: t('MenuDemoMenusTitle', {menu: "A"}),
                            en: t('MenuDemoMenusTitle', {menu: "A"}),
                        },
                        /*startingNote: "starting note", */       
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 1}),
                                    en: t('MenuDemoStarterTitle', {nb: 1})
                                },
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 2}),
                                    en: t('MenuDemoStarterTitle', {nb: 2})
                                },
                            },
                            {
                                entryId: 2,
                                separator: true,
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 1}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 1})
                                },
                            }, 
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 2}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 2})
                                },
                            },  
                            {
                                entryId: 5,
                                separator: true,
                            },
                            {
                                entryId: 6,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitle', {nb: 1}),
                                    en: t('MenuDemoDessertTitle', {nb: 1}),
                                },
                            }, 
                            {
                                entryId: 7,
                                entryTitle: {
                                    fr: t('MenuDemoDessertTitle', {nb: 2}),
                                    en: t('MenuDemoDessertTitle', {nb: 2}),
                                },
                            },                                 
                        ],    
                        /*endingNote: "ending note",     */             
                    },
                    {
                        entryType: "formula",
                        entriesTitle: {
                            fr: t('MenuDemoMenusTitle', {menu: "B"}),
                            en: t('MenuDemoMenusTitle', {menu: "B"}),
                        },
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoMenusDesc1'),
                                    en: t('MenuDemoMenusDesc1'),
                                },                                
                            },
                        ]
                    },                                      
                    /*{
                        entryType: "endingNote",
                        text: "ending note",
                        centering: 'center'
                    },*/
                ]
            },            
            {
                pageType: {fr: "Entrées", en: "Starters"},                     
                pageEntries: [
                    /*{
                        entryType: "startingNote",
                        text: "starting note",
                        centering: 'center'
                    },*/
                    {
                        entryType: "dishList",
                        /*entriesTitle: "Salades",
                        startingNote: "starting note",*/
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 1}),
                                    en: t('MenuDemoStarterTitle', {nb: 1})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 1}),
                                    en: t('MenuDemoStarterDesc', {nb: 1})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 2}),
                                    en: t('MenuDemoStarterTitle', {nb: 2})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 2}),
                                    en: t('MenuDemoStarterDesc', {nb: 2})
                                },  
                                entryPrice: {fr: ["X €"], en: ["X €"]},                          
                            },
                            {
                                entryId: 2,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 3}),
                                    en: t('MenuDemoStarterTitle', {nb: 3})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 3}),
                                    en: t('MenuDemoStarterDesc', {nb: 3})
                                }, 
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 4}),
                                    en: t('MenuDemoStarterTitle', {nb: 4})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 4}),
                                    en: t('MenuDemoStarterDesc', {nb: 4})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 5}),
                                    en: t('MenuDemoStarterTitle', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoStarterDesc', {nb: 5}),
                                    en: t('MenuDemoStarterDesc', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },                    
                        ],
                        /*endingNote: "ending note",*/
                    },
                    /*{
                        entryType: "dishList",
                        entriesTitle: "Antipasti",
                        startingNote: "starting note",                        
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 1}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 1}),
                                entryPrice: {fr:["37.5 cl : X €", "75 cl : X €"],en:["37.5 cl : X €", "75 cl : X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 1,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 2}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 2}),                                
                            },
                            {
                                entryId: 2,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 3}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 3}),
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },
                            {
                                entryId: 3,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 4}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 4}),
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 4,
                                entryTitle: t('MenuDemoStarterTitle', {nb: 5}),
                                entrySubtitle: t('MenuDemoStarterDesc', {nb: 5}),
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_starter.jpg",
                            },                    
                        ],
                        endingNote: "ending note",                        
                    },
                    {
                        entryType: "endingNote",
                        text: "ending note",
                        centering: 'center'
                    },*/
                ]
            },
            {
                pageType: {fr: "Plats", en: "Dishes"},
                pageEntries: [
                    {
                        entryType: "dishList",
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 1}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 1})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDesc', {nb: 1}),
                                    en: t('MenuDemoMainCourseDesc', {nb: 1})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_maincourse.jpg",
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 2}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 2})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDesc', {nb: 2}),
                                    en: t('MenuDemoMainCourseDesc', {nb: 2})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 2,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 3}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 3})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDesc', {nb: 3}),
                                    en: t('MenuDemoMainCourseDesc', {nb: 3})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_maincourse.jpg",
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 4}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 4})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDesc', {nb: 4}),
                                    en: t('MenuDemoMainCourseDesc', {nb: 4})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('MenuDemoMainCourseTitle', {nb: 5}),
                                    en: t('MenuDemoMainCourseTitle', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoMainCourseDesc', {nb: 5}),
                                    en: t('MenuDemoMainCourseDesc', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_maincourse.jpg",
                            },
                        ],
                    }
                ],
            },
            {
                pageType: {fr: "Desserts", en: "Desserts"},
                pageEntries: [
                    {
                        entryType: "dishList",
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 1}),
                                    en: t('MenuDemoStarterTitle', {nb: 1})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDesc', {nb: 1}),
                                    en: t('MenuDemoDessertDesc', {nb: 1})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_dessert.jpg",
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 2}),
                                    en: t('MenuDemoStarterTitle', {nb: 2})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDesc', {nb: 2}),
                                    en: t('MenuDemoDessertDesc', {nb: 2})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 2,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 3}),
                                    en: t('MenuDemoStarterTitle', {nb: 3})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDesc', {nb: 3}),
                                    en: t('MenuDemoDessertDesc', {nb: 3})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_dessert.jpg",
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 4}),
                                    en: t('MenuDemoStarterTitle', {nb: 4})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDesc', {nb: 4}),
                                    en: t('MenuDemoDessertDesc', {nb: 4})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                            },
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('MenuDemoStarterTitle', {nb: 5}),
                                    en: t('MenuDemoStarterTitle', {nb: 5})
                                },                                
                                entrySubtitle: {
                                    fr: t('MenuDemoDessertDesc', {nb: 5}),
                                    en: t('MenuDemoDessertDesc', {nb: 5})
                                },
                                entryPrice: {fr: ["X €"], en: ["X €"]},
                                entryImage: "/images/img_dessert.jpg",
                            },
                     ],
                    },
                ],
            },
            {
                pageType: {fr: "Vins", en: "Wines"},
                pageEntries: [
                    {
                        entryType: "dishList",
                        entries: [
                            {
                                entryId: 0,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 1}),
                                    en: t('WineDemoTitle', {nb: 1}),
                                },   
                                entryPrice: {fr: [t('WineDemoPrices')], en: [t('WineDemoPrices')]},
                            },
                            {
                                entryId: 1,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 2}),
                                    en: t('WineDemoTitle', {nb: 2}),
                                }, 
                                entryPrice: {fr:[t('WineDemoPrices2')],en:[t('WineDemoPrices2')]},
                            },
                            {
                                entryId: 2,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 3}),
                                    en: t('WineDemoTitle', {nb: 3}),
                                }, 
                                entryPrice: {fr: [t('WineDemoPrices')], en: [t('WineDemoPrices')]},
                            },
                            {
                                entryId: 3,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 4}),
                                    en: t('WineDemoTitle', {nb: 4}),
                                }, 
                                entryPrice: {fr:[t('WineDemoPrices2')],en:[t('WineDemoPrices2')]},
                            },
                            {
                                entryId: 4,
                                entryTitle: {
                                    fr: t('WineDemoTitle', {nb: 5}),
                                    en: t('WineDemoTitle', {nb: 5}),
                                }, 
                                entryPrice: {fr: [t('WineDemoPrices')], en: [t('WineDemoPrices')]},
                            },
                     ],
                    },
                ],
            },
            {
                pageType: {fr: "Note", en: "Note"},
                pageEntries: [
                    {
                        entryType: "note",
                        text: {
                            fr: t('EndingPageDemoText'),
                            en: t('EndingPageDemoText'),
                        },
                        centering: 'start',
                    },
                ]
            },
        ];
    }
}

export function getEmojiFromPageType(pageType){
    switch (pageType){
        case "Starters":
            return "🥗";
        case "Dishes":
            return "🥘";
        case "Desserts":
            return "🍰"; 
        case "Wines":
            return "🍷";
        case "Drinks":
            return "🍹";
        case "Menus":
            return "🍽️";
        case "Note":
            return "👋";
            
        default:
            return "";
    }
}

export default Menu;