import './../../Player.css';
import React, {useContext, useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsManager } from '../../../index';
import PrimaryButton, {shareType} from '../components/PrimaryButton';
import {PlayerContext} from '../../PlayerContext';
import { Alert, Snackbar } from '@mui/material';
import ShareDialog from './ShareDialog';
import { useAnimate } from 'framer-motion';
import { argbFromHex, hexFromArgb, TonalPalette } from '@material/material-color-utilities';

function ShareButton(props){
    const { t } = useTranslation();
    const [scope, animate] = useAnimate();

    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");
    const playMode = useContext(PlayerContext).get("playMode");
    const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
    const playAudio = useContext(PlayerContext).get("playAudio");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const fromDemo = useContext(PlayerContext).get("fromDemo");
    const idPrefix = fromDemo ? (fromDemo +"-") : "";
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const [shareDialogOpened, setShareDialogOpened] = useState(false);
    const [showClipboard, setShowClipboard] = useState(false);
    const pauseVideo = useContext(PlayerContext).get("pauseVideo");
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const setShareButtonAlreadyHighlighted = useContext(PlayerContext).get("setShareButtonAlreadyHighlighted");
    const shareButtonAlreadyHighlighted = useContext(PlayerContext).get("shareButtonAlreadyHighlighted");
    const insideStickerClicked = useContext(PlayerContext).get("insideStickerClicked");
    const menuStickerClicked = useContext(PlayerContext).get("menuStickerClicked");


    let shareControls = useRef(null);
    function playAnimation(){
        const shareBtn = document.getElementById("primary-btn-share");          
        if (shareBtn != null){              
            const animationSequence = [                      
                [shareBtn, { backgroundColor: "#FFFFFF"}, {ease: "easeIn", duration: 0.6}],
                [shareBtn, { backgroundColor: "#FFFFFF"}, {duration: 0.2}],
                [shareBtn, { backgroundColor: props.story.primaryColor}, {ease: "easeOut", duration: 1.2}],
            ];            
            
            shareControls.current = animate(animationSequence);        
        }
    }
    
    useEffect(() => {                
        if (! fromMaker && ! fromDemo && props.story && props.show && !shareButtonAlreadyHighlighted && (props.story.tourMediasRef || props.story.mediasRef || props.story.menuId) && (insideStickerClicked || !props.story.tourMediasRef) && (menuStickerClicked || !props.story.mediasRef && !props.story.menuId)){                        
            setTimeout(()=>playAnimation(), 1000);             
            setShareButtonAlreadyHighlighted(true);                
        }

    }, [props.story, props.show, shareButtonAlreadyHighlighted, insideStickerClicked, menuStickerClicked]);

    const open = async () => {         
        // do not trigger FS on share (bug on chrome android) - FS + share dialog = visually strange
        // openFullscreen();    
        
        if (!fromMaker /*&& !fromDemo*/ && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){                        
            const ambiantPlayer = document.getElementById(idPrefix + "audio-player");
            const volumeBtnOn = document.getElementById(idPrefix + "story-volume-btn-on");
            const volumeBtnOff = document.getElementById(idPrefix + "story-volume-btn-off");
            if (ambiantPlayer && ambiantPlayer.paused){
                try {
                    ambiantPlayer.play();                
                } catch(error){}
                if (volumeBtnOn && volumeBtnOff){
                    volumeBtnOff.style.visibility = "visible";
                    volumeBtnOn.style.visibility = "hidden";
                }
            }
            setPlayAudio(true);            
        }
        
        try {           
            const shareParams = {
                /*title: t("ShareTitle"),*/ // does not work most of the time with the target app + make the ico logo appear in bad quality on GMail inetgrated browser (Android)
                text: t("ShareText", {name: props.placeDetails != null ? (props.placeDetails.displayName.text !== "Holberton School Paris" ? props.placeDetails.displayName.text : "Holberton School") : t("ShareTextDefaultName")}),
                url: props.story.storyLink/* + "?utm_source=upreality&utm_medium=share"*/   
            };
            await navigator.share(shareParams);          
        }
        catch(error){              
            if (! error.toString().includes('AbortError')) {  
                pauseVideo();   
                setShareDialogOpened(true);
                setActiveFeature("share");              
            }            
        }
        if (appAnalyticsManager)
            appAnalyticsManager.clickedShare();        
        analyticsManager.logShareTag(props.storyId, playMode);
        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "share",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "share"});
        }
    }

    if (props.show && props.placeDetails){
        if (shareDialogOpened){
            return <ShareDialog opened={shareDialogOpened} onClose={(showClipboardSnack)=>{setShareDialogOpened(false);if (showClipboardSnack){setShowClipboard(true);}setActiveFeature(null);propagateFocusChange(true);}} story={props.story} storyId={props.storyId} placeDetail={props.placeDetails}/>
        }
        else {
            return <>
                        <PrimaryButton name="button-share" buttonType={shareType} open={open} story={props.story} storyId={props.storyId}/>
                        {showClipboardSnack()}
                    </>
        }
    }
    else {
        return null;
    }

    function showClipboardSnack(){
        if (showClipboard){
            return (
                <Snackbar open={showClipboard} autoHideDuration={2000} onClose={()=>setShowClipboard(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowClipboard(true)} severity="success">     
                    {t('AckLinkCopied')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }
    
}

export default ShareButton;