import { useContext, useEffect, useState } from "react";
import { analyticsManager } from "..";
import { useMediaQuery } from "react-responsive";
import { LoggedContext } from "./LoggedContext";
import { useTranslation } from "react-i18next";
import MaterialButton from "../maker/component/MaterialButton";
import { AppContext } from "../AppContext";
import { associateEmailToFacebook, createUserIfFirstLogin } from "../maker/MakerController";
import * as Sentry from "@sentry/react";
import { Alert, FormControl, InputLabel, Snackbar, TextField } from "@mui/material";
import { browserLocales, isEmailValid, printObj } from "../player/Utils";
import { deleteUser, getAuth, signOut } from "firebase/auth";
import ConfirmDialog from "../maker/component/ConfirmDialog";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { deleteStoriesFromUser, deleteUserFromDB, getStories, getUser, transferToAnotherAccount } from './PreferencesController';
import useForceUpdate from 'use-force-update';
import { useNavigate } from "react-router-dom";
import './Logged.css';
import { INSTANT_AD_HP } from "../AppRoot";

function Preferences(props) {
    const windowDimensions = useContext(LoggedContext).get("windowDimensions");
    const setActivePage = useContext(LoggedContext).get("setActivePage");
    const fbResponse = useContext(AppContext).get("fbResponse");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const setJustDeletedAccount = useContext(AppContext).get("setJustDeletedAccount");
    const homePageOrigin = useContext(AppContext).get("homePageOrigin");    
    const [showAckFacebookLinked, setShowAckFacebookLinked] = useState(false);    
    const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);
    const [showTransferConfirm, setShowTransferConfirm] = useState(false);
    const [showDeleteAccountConfirm, setShowDeleteAccountConfirm] = useState(false);    
    const [stories, setStories] = useState(null);
    const [currentStory, setCurrentStory] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [showErrRecentLogin, setShowErrRecentLogin] = useState(false);
    const [showProgress, setShowProgress] = useState(false);  
    const forceUpdate = useForceUpdate();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentStoryName = stories != null && currentStory != null ? stories.find((story) => story.id === currentStory).posName : null;
    const currentStoryPublicationDate = stories != null && currentStory != null ? stories.find((story) => story.id === currentStory).publicationDate : null;
    const currentStoryPublicationDateFormatted = currentStoryPublicationDate != null ? new Date(currentStoryPublicationDate).toLocaleDateString(browserLocales(), new Date().getTimezoneOffset()) : t('NotPublished');

    useEffect(() => {
        analyticsManager.logPreferencesScreenViewTag();
        if (window.history.pushState) {   
            var newurl = window.location.protocol + "//" + window.location.host + "/logged" + '/' + "preferences";
            window.history.pushState({path:newurl},'',newurl);
        }  
    }, []);

    useEffect(() => {      
        async function getStoriesForOptions(){
            const currentUser = getAuth().currentUser;
            const stories = await getStories(currentUser.uid);
            setStories(stories);
        }

        getAuth()
            .authStateReady()
            .then(() => {                
                getStoriesForOptions();
            });
                        
      }, []);

    let pageHeight = makerDesktopHeight;
    if (isTabletOrMobile && windowDimensions != null) {
        pageHeight = windowDimensions.height - 56;
    }

    function associateToFacebook(){
        analyticsManager.logAssociateToFacebookTag();
        associateEmailToFacebook()
            .then(() => {
                setShowAckFacebookLinked(true);
            })
            .catch((error) => {
                // TODO custom dialog in this case
                Sentry.captureMessage(printObj(error));
            });
    }

    function showAckFacebookLinkedSnack(){
        if (showAckFacebookLinked){
            return (
                <Snackbar open={showAckFacebookLinked} autoHideDuration={6000} onClose={()=>setShowAckFacebookLinked(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowAckFacebookLinked(false)} severity="success">     
                    {t('EmailLinkedToFacebook')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    } 

    const storiesOptions = stories != null ? stories.filter((story) => story.transferTo == null).map((story) => {
        return (
            <MenuItem key={story.id} value={story.id}>
                <div>{story.posName} {!isTabletOrMobile && <i>{"(" + (story.publicationDate != null ? new Date(story.publicationDate).toLocaleDateString(browserLocales(), new Date().getTimezoneOffset()) : t('NotPublished')) + ")"}</i>}</div>                
            </MenuItem>
        );
    }): null;

    const storiesTransfered = stories != null ? stories.filter((story) => story.transferTo != null).map((story) => {        
        const storyName = story.posName + " (" + (story.publicationDate != null ? new Date(story.publicationDate).toLocaleDateString(browserLocales(), new Date().getTimezoneOffset()) : t('NotPublished')) + ")";
            return (
                <div key={story.id} style={{display: 'flex', flexDirection: 'row', fontStyle: 'italic', fontSize: 10, alignItems: 'center'}}>
                    {t('TransferInProgress', {name: storyName, email: story.transferTo})}
                    <div style={{marginLeft: 16,}}/>                       
                    <MaterialButton style="text" onClick={()=>cancelTransferToAnotherAccount(story.id)} value={t('x')}/>
                </div>
            );
        }): null;

    async function cancelTransferToAnotherAccount(storyId){
        analyticsManager.logTransferTag(false);
        transferToAnotherAccount(storyId, null)
            .then(() => {                
                stories.find((story) => story.id === storyId).transferTo = null;                
                setStories(stories);
                // TODO why setStories does not refresh here ?    
                forceUpdate();                    
            });        
    }

    function handleChangeStory(event){        
        setCurrentStory(event.target.value);
    }  

    async function confirmTransferToAnotherAccount(){
        analyticsManager.logTransferTag(true);
        transferToAnotherAccount(currentStory, document.getElementById("transferRecipient").value)
            .then(() => {
                stories.find((story) => story.id === currentStory).transferTo = document.getElementById("transferRecipient").value;
                setStories(stories);
                setCurrentStory(null);
                document.getElementById("transferRecipient").value = "";                
                setShowTransferConfirm(false);
            });        
    }    

    async function deleteAccount(){        
        analyticsManager.logDeleteAccountTag();
        setShowProgress(true);
        const user = await getUser(fbResponse.uid)

        deleteStoriesFromUser(fbResponse.uid)
            .then(() => setJustDeletedAccount(true))
            .then(() => deleteUserFromDB(fbResponse.uid))
            .then(() => deleteUser(fbResponse))              
            .then(() => {
                if (homePageOrigin === INSTANT_AD_HP){
                    navigate("/Instant-Ad?ackAccountDeleted=true"); 
                }   
                else {                                            
                    navigate("/?ackAccountDeleted=true"); 
                } 
            })
            .catch((error) => {                
                setShowDeleteAccountConfirm(false);
                // TODO change for login just before delete
                if (error.code === "auth/requires-recent-login"){
                    createUserIfFirstLogin(user.id, user.email, user.fbid, null, true);
                    setShowErrRecentLogin(true);                    
                }
            })
            .finally(() => {
                setShowProgress(false);
            });
    }    

    function showErrRecentLoginSnack(){
        if (showErrRecentLogin){            
            return (
                <Snackbar open={showErrRecentLogin} onClose={()=>setShowErrRecentLogin(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowErrRecentLogin(false)} severity="error" sx={{ width: '100%' }}>     
                    {t('ErrRecentLogin')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    return (
        <div style={{width: '100%', height: pageHeight, backgroundColor: 'white', borderRadius: isTabletOrMobile ? 0 : 10,}}>
            <div className="list-account-features" style={{padding: 16, height: pageHeight - 32}}>
                <div style={{fontSize: 28}}>{t('ManageMyAccountTitle')}</div>            
                <div style={{marginTop: 20}}/>
                {fbResponse != null && fbResponse.providerData.length != 2 && fbResponse.providerData[0].providerId === "password" && 
                    <>        
                        <div style={{fontSize: 20, marginBottom: 10}}>{t('AssociateAccounts')}</div>                
                        <MaterialButton style="text" onClick={() => associateToFacebook()} value={t('AssociateEmailToFacebook')}/>                                        
                    </>
                }
                {fbResponse != null && fbResponse.providerData.length === 2 && 
                    <>                      
                        <div style={{fontSize: 20, marginBottom: 10}}>{t('AssociateAccounts')}</div>  
                        <i>{t('EmailLinkedToFacebookStatus')}</i>
                    </>
                }                
                <div style={{marginTop: 20, }}>
                    <div style={{fontSize: 20}}>{t('TransferToAnotherAccount')}</div>
                    <div style={{marginTop: 10, width: 200, display: "flex", flexDirection: "row", justifyContent: 'flex-start', alignItems: 'center', alignContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', alignContent: 'start'}}>
                            <FormControl  sx={{ minWidth: 240 }} size="small">
                                <InputLabel id="select-transfer-to-label">{t('TransferHint')}</InputLabel>
                                <Select                                               
                                    id="select-transfer-to"
                                    labelId="select-transfer-to-label"
                                    value={currentStory != null ? currentStory : ''}
                                    label={t('TransferHint')}
                                    sx={{ width: '100%', }}
                                    onChange={handleChangeStory}
                                    >
                                    {
                                        storiesOptions
                                    }
                                </Select>
                            </FormControl>

                            <TextField id='transferRecipient' type='text' style={{marginTop: 8, width: 240}} error={emailError} onBlur={()=>{
                                if (isEmailValid(document.getElementById("transferRecipient").value) || document.getElementById("transferRecipient").value === ""){
                                    setEmailError(false);
                                }
                                else {
                                    setEmailError(true);
                                }
                            }} placeholder={t('TransferRecipient')} />
                        </div> 
                        <div style={{marginLeft: 16,}}/>                       
                        <MaterialButton style="text" reducedPadding={true} onClick={()=>{
                            if (document.getElementById("transferRecipient").value === "" && currentStory == null) return;

                            if (isEmailValid(document.getElementById("transferRecipient").value) && currentStory != null){
                                setShowTransferConfirm(true);
                                analyticsManager.logOpenPopupTag(currentStory, "transfer", true);
                            }
                            else {
                                setEmailError(true);
                            }
                        }} value={t('ok')}/>
                    </div>
                    <div style={{marginTop: 10, display: "flex", flexDirection: "column"}}>
                        {storiesTransfered}
                    </div>
                </div>
                <div style={{marginTop: 20}}>
                    <div style={{fontSize: 20, marginBottom: 10}}>{t('DeleteMyAccount')}</div>
                    <MaterialButton style="text" onClick={()=> {analyticsManager.logOpenPopupTag(currentStory, "delete_account", true);setShowDeleteAccountConfirm(true);}} value={t('DeleteMyAccount')}/>
                </div>
                {isTabletOrMobile &&
                    <>
                        <div style={{marginTop: 20}}>
                            <div style={{fontSize: 20, marginBottom: 10}}>{t('Help')}</div>
                            <MaterialButton style="text" onClick={()=> { analyticsManager.logClickHelpTag();setActivePage("help");}} value={t('ReadFAQ')}/>
                        </div>
                    </>
                }
                <div style={{marginTop: 20}}>
                    <div style={{fontSize: 20, marginBottom: 10}}>{t('CheckMyRights')}</div>
                    <MaterialButton style="text" onClick={()=> {analyticsManager.logReadLegalsTag(); setActivePage("legals");}} value={t('ReadLegals')}/>
                </div>
                {isTabletOrMobile &&
                    <>
                        <div style={{marginTop: 40, width: '100%', display: "flex", flexDirection: "row", justifyContent: 'center', }}>
                            <MaterialButton style="outlined-tonal" onClick={() => {
                                setShowDisconnectConfirm(true);
                                analyticsManager.logOpenPopupTag(null, "logout", true);
                            }} value={t('LogOut')}/>   
                        </div>
                    </>
                }                
            </div>            
            {showTransferConfirm && <ConfirmDialog showConfirm={showTransferConfirm} setShowConfirm={setShowTransferConfirm} dialogTitle={t('TransferToAnotherAccount')} dialogText={t('TransferToAnotherAccountWarning', {name: currentStoryName, date: currentStoryPublicationDateFormatted})} dialogAction={t('TransferAction')} tagName="transfer" onSuccess={()=>{
                confirmTransferToAnotherAccount();
            }} />} 
            {showDeleteAccountConfirm && <ConfirmDialog showConfirm={showDeleteAccountConfirm} setShowConfirm={setShowDeleteAccountConfirm} dialogTitle={t('DeleteMyAccount')} dialogText={t('DeleteMyAccountWarning')} dialogAction={t('DeleteAction')} tagName="delete" showProgress={showProgress} onSuccess={()=>{
                deleteAccount();
            }} />} 
            {showDisconnectConfirm && <ConfirmDialog showConfirm={showDisconnectConfirm} setShowConfirm={setShowDisconnectConfirm} dialogTitle={t('LogOut')} dialogText={t('LogOutWarning')} dialogAction={t('LogOut')} tagName="logout" onSuccess={()=>{
                        analyticsManager.logLogoutTag(true);
                        signOut(getAuth()); 
                    }} />} 
            {showAckFacebookLinkedSnack()}
            {showErrRecentLoginSnack()}
        </div>
    );
}

export default Preferences;