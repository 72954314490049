import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import LineBreak from "./LineBreak";
import { analyticsManager } from "../..";
import { useState } from "react";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import MaterialButton from "./MaterialButton";

function Create1ClicDialog(props){
    const { t } = useTranslation();

    const [igProfileMissing, setIgProfileMissing] = useState(null);
    const [contactEmailMissing, setContactEmailMissing] = useState(null);
    const [showAckCreate1Clic, setShowAckCreate1Clic] = useState(false);

    function close(){                        
        analyticsManager.logOpenPopupTag(null, props.show, false);
        setShowAckCreate1Clic(false);
        props.closeDialog(false);
    }

    function checkIGProfileForm(fromInputOverSubmit = false){
        const instagramProfile = document.getElementById('instagram-profile').value;
        if (instagramProfile.trim() === "" && !fromInputOverSubmit){
            setIgProfileMissing(t("MandatoryField"));
            return false;
        }
        else if (instagramProfile.trim() !== "" && igProfileMissing != null){
            setIgProfileMissing(null);
        }

        return true;
    }

    function checkContactEmailForm(fromInputOverSubmit = false){
        const contactEmail = document.getElementById('contact-email').value;
        if (contactEmail.trim() === "" && !fromInputOverSubmit){
            setContactEmailMissing(t("MandatoryField"));
            return false;
        }
        else if (contactEmail.trim() !== "" && contactEmail != null){
            setContactEmailMissing(null);
        }

        return true;
    }

    function sendEmail(){
        const db = getFirestore();
        const instagramProfile = document.getElementById('instagram-profile').value; 
        const contactEmail = document.getElementById('contact-email').value;
        addDoc(collection(db, "mail"), {
            to: "jacques@upreality.store",
            from: "Jacques - UpReality <jacques@upreality.store>",
            message: {
                subject: "UpReality 🌊 - demande de création de site web",                    
                text: instagramProfile + ", " + contactEmail + " a demandé la création d'un site web en 1 clic, penser à vérifier l'identité de l'email.",
            },
        });             
    }

    function submit(){
        if (checkIGProfileForm() && checkContactEmailForm()){
            sendEmail();                
            close();
            setShowAckCreate1Clic(true);
            if (props.origin === "home_page"){
                analyticsManager.logHPCreate1Clic(props.show);
            }
            else if (props.origin === "maker"){
                analyticsManager.logMakerdCreate1Clic();
            }
        }
    }

    function showAckCreate1ClicSnack(){
        if (showAckCreate1Clic){            
            return (
                <Snackbar open={showAckCreate1Clic} onClose={()=>setShowAckCreate1Clic(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowAckCreate1Clic(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckCreate1Click')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    if (props.show){
        return (
            <Dialog onClose={close} open={props.show !== false} >
                <DialogTitle style={{userSelect: 'none'}}>{t('CTA')}</DialogTitle>                    
                    <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16,}}>
                    <div style={{fontStyle: 'italic', fontSize: 14}}>                        
                        {t('Create1ClickHelp0')}   
                        <div style={{marginTop: 20}}/>
                    </div>  
                    <TextField id='instagram-profile' fullWidth error={igProfileMissing != null} onChange={()=>checkIGProfileForm(true)} helperText={igProfileMissing} placeholder={t('ProfileInstagramHint')} />                           
                    <LineBreak/> 
                    <TextField id='contact-email' fullWidth error={contactEmailMissing != null} onChange={()=>checkContactEmailForm(true)} helperText={contactEmailMissing} placeholder={t('ContactEmailHint')} />   
                    {/*<>
                        <LineBreak/>                        
                        <LineBreak/>
                        <div style={{fontStyle: 'italic', fontSize: 14}}>
                            {t('Create1ClickHelp1')}                        
                        </div>
                        <LineBreak/>
                        <MaterialButton icon="/images/ic_wetransfer.png" style="wetransfer" onClick={()=>{analyticsManager.logClickWeTransferTag(null, "create1Click_" + props.origin);window.open("https://wetransfer.com/?to=jacques@upreality.store", "_blank");}} value={t("SendMyContentWithWeTransfer")} />      
                    </>*}
                    {/*<div style={{fontStyle: 'italic', fontSize: 14}}>
                        <div style={{marginTop: 40}}/>
                        {t('Create1ClickHelp2')}   
                    </div>*/}
                    </DialogContent>
                    <DialogActions style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', padding: 16,}}>
                        <MaterialButton style="text" value={t('Cancel')} onClick={close}/>
                        <MaterialButton style="text" value={t('CTA')} onClick={()=>{submit();}}/>
                    </DialogActions>                   
            </Dialog>
        );
    }
    else return (<>{showAckCreate1ClicSnack()}</>);

}

export default Create1ClicDialog;
